// export const lang = {

const Lang = () => ({
    cz: {
        controls: {
            play: 'Přehrát',
            pause: 'Pauza',
            live: 'Živě',
            next: 'Další',
            upNext: 'Další v pořadí',
            playNow: 'Přehrát nyní',
            nextVideoCancel: 'Vypnout další video',
            replay: 'Přehrát znovu',
            volume: 'Hlasitost',
            mute: 'Ztlumit',
            unmute: 'Zapnout zvuk',
            settings: 'Nastavení',
            theater: 'Kino',
            fullscreen: 'Celá obrazovka',
            chromecast: 'Chromecast',
            airplay: 'Airplay',
            pictureInPicture: 'Obraz v obraze',
            skipIntro: 'Přeskočit intro',
            playlistTitle: 'Pokračovat ve sledování',
            continueAfterPause: 'Pokračovat ve sledování',
            related: 'Related',
        },
        settings: {
            quality: 'Kvalit',
            subtitles: 'Titulky',
            autoplay: 'Autoplay',
            playbackRate: 'Rychlost přehrávání',
            auto: 'Auto',
            off: 'Vypnout',
            normal: 'Normální',
            share: 'Sdílet',
            autoplayOff: 'Vypnout autoplay',
            autoplayOn: 'Zapnout autoplay',
            zoomedToFill: 'Přiblížit',
            zoomOut: 'Oddálit',
            originalZoom: 'Původní velikost',
            captionsOff: 'Vypnout titulky',
            chromeCastPlayingOn: 'Přehrávám přes Chromecast',
            dismiss: 'Zavřít',
        },
        ads: {
            skip: 'Přeskočit',
            skipIn: 'Přeskočit za',
            visit: 'Navštívit',
            info: 'Info',
            simultaneousAds: 'Reklama {current} z {total}',
            adAnnouncement: 'Reklama za',
        },
        messages: {
            playbackErrorTitle: 'Problém s přehráváním videa',
            playbackErrorDescription: 'Toto video momentálně nelze přehrát.',
            geoBlockedTitle: 'Video není dostupné ve vaší zemi',
            geoBlockedDescription:
                'Bohužel vydavatel neumožnil přehrávání tohoto videa ve vaší zemi.',
            streamInterruptedTitle: 'Živý přenos je přerušený',
            streamInterruptedDescription: 'Něco se pokazilo. Zkuste znovu načíst stránku.',
            streamStartingTitle: 'Živý přenos brzy začne',
            streamStartingDescription: 'Prosím vyčkejte, živý přenos již brzy začne.',
            unpublishedTitle: 'Video není publikovano',
            unpublishedDescription: 'Bohužel toto video ještě nebylo publikováno.',
            privateTitle: 'Video je soukromé',
            privateDescription: 'Video nelze přehrát, protože je označené jako soukromé.',
        },
        months: [
            'ledna',
            'února',
            'března',
            'dubna',
            'května',
            'června',
            'července',
            'srpna',
            'září',
            'října',
            'listopadu',
            'prosince',
        ],
    },
});

export { Lang };
