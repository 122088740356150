import { AppWebService } from './analytics/AppWebService';
import { GemiusService } from './analytics/GemiusService';
import { browserIsMobile, browserPlatform } from './utils/CheckBrowser';
import { randomString, utilsConsole } from './utils/playerUtils';
import { gemiusConfig, vpProjectsIds } from './config/cncGemiusVideoConfig';

// define players arr
if (typeof window.cncGjvpPlayers === 'undefined') {
    window.cncGjvpPlayers = [];
}

export function CncVpDash(config) {
    const self = this;
    /* eslint-disable no-unused-vars */
    self.version = '1.2.0-dash';
    /* eslint-enable no-unused-vars */
    let viewId = false;

    const { configCNC, vpPlayer: configVpPlayer } = config;
    const { projectId } = configVpPlayer;
    let magazineId = configCNC.video.category.magazine || '';

    if (configCNC.category && configCNC.category.magazine) {
        magazineId = configCNC.category.magazine;
    }

    const gemiusSetting = gemiusConfig().getGemiusSetting(magazineId, configCNC.gemius);

    // ulozime si zdali ma video preroll reklamu nebo nejakou dalsi reklamu na 0 sekunde videa
    let prerollAd = false;
    const progressCuePointsTimes = [];
    // globalni nastaveni:
    const playerType = configCNC.video.miniplayer ? 'videoMini' : 'videoNormal';
    configCNC.isMobile = browserIsMobile();
    // u mute/unmute eventu v reklame nechodi id reklamy proto si ho ulozime narozdil od ads play.pause
    let adPlayId;

    let currentVideo; // aktualni video z playlistu VP playeru
    const webAppConfig = {
        premium: configCNC.appWeb.contentPremium,
        noDidomi: configCNC.appWeb.noDidomi,
        settingsAutoplay: false,
        gemiusTypePlayer: '',
        video: {
            playerType,
            id: configCNC.video.id,
            flags: [],
            dateStart: configCNC.video.dateStart,
            title: configCNC.video.title,
            playerVariant: configCNC.appWeb.playerVariant,
            contentType: configCNC.appWeb.contentType,
            source: configCNC.video.source,
            durationSeconds: parseInt(configCNC.video.durationSeconds, 10),
            elementId: configCNC.video.elementId,
            autoPlay: false, // todo doresit kdyz se posuti prvni 10s video je to autoplay?
            mute: false, // todo doresit
            show: configCNC.gemius.series,
            category: configCNC.video.category,
            contentTags: [
                ...new Set( // Deduplikace přes transformaci na Set a zpět na Array
                    configCNC.video.keywords
                        .replace(/_v\d+/, '') // Nahrazení číslování verze přidávaného na BE
                        .split(',')
                        .map((s) => s.trim())
                        .filter(Boolean), // Odstranění prázdných stringů, předpokládáme, že 0 není v keywords
                ),
            ],
        },
        // deprecated
        appWeb: {
            // po nasazeni dash playeru, pouzivat jen v rootu webAppConfig
            noDidomi: configCNC.appWeb.noDidomi,
        },
    };

    utilsConsole('webAppConfig', webAppConfig);

    /* ga4 */
    /**
     * AppWebservice odesilame na jednom miste, abychom nemuseli pred kazdym odeslanim kontrolovat zdali mame
     * @param serviceFunction
     * @param settingObject
     * @returns {null|boolean}
     */
    self.sendAppService = (serviceFunction, settingObject) =>
        new Promise((resolve) => {
            utilsConsole(`sendAppService | sendAppService.serviceFunction > ${serviceFunction}`);
            if (self.appWebService && serviceFunction in self.appWebService) {
                const res = self.appWebService[serviceFunction](settingObject);
                utilsConsole(
                    `sendAppService result | sendAppService.serviceFunction > ${serviceFunction}`,
                    settingObject,
                    res,
                );
                resolve(true);
            }
            resolve(false);
        });

    self.appWebService = new AppWebService({ video: configCNC.video, appWeb: configCNC.appWeb });

    /* ga4 end */

    // self.playlist = [];
    self.player = false;
    self.wasInteraction = false;
    self.firstInteraction = false;
    self.streamType = 'application/x-mpegURL';

    self.construct = () => {
        utilsConsole('construct');
        // set first video
        // self.addFirstVideo();
        if (viewId === false) {
            viewId = randomString(24);
        }

        // var video = configCNC.video;
        configCNC.viewId = viewId;
        self.currentVideo = configCNC.video;
        configCNC.eventPlaySend = false;

        utilsConsole(configCNC.video.elementId);

        if (browserPlatform() === 'mac') {
            self.streamType = 'application/vnd.apple.mpegurl';
        }

        const addVpEvent = (data) => {
            self.player = window.vpPlayer(configCNC.video.elementId);

            // overwrite shouldLockVideo with custom detection if user is logged or logged with premium
            const CNCUserStatus = document.querySelector('meta[name="CNCUserStatus"]').content;
            const videoFlags = configCNC.lockFlag;

            const isEnabledDetection = () => {
                if (
                    (videoFlags.includes('premium') && CNCUserStatus === 'premium') ||
                    (videoFlags.includes('registered') &&
                        (CNCUserStatus === 'registered' || CNCUserStatus === 'premium'))
                ) {
                    return false;
                }

                return true;
            };

            if (CNCUserStatus) {
                self.player.shouldLockVideo = () => {
                    // TODO FEM-2157 odstranit nacitani casu a zamykani z RING CMS po uprave spravneho fungovani na BE
                    const lockTime = configCNC.lockTime
                        .split(':')
                        .reduce((acc, time) => 60 * acc + +time);

                    const isEnabled = isEnabledDetection() && configCNC.isVideoLockedInTime;
                    const { type, value } = { type: 'seconds', value: lockTime };

                    // TODO docasny hack, protoze video zamcene v 00:00 neposila event videoLocked
                    if (lockTime === 0 && configCNC.isVideoLockedInTime) {
                        self.lockedInTimeOverlay(configCNC.video.elementId);
                    }

                    return { isEnabled, type, value };
                };
            }

            self.player.setup(data).then(() => {
                self.player = window.vpPlayer(configCNC.video.elementId);
                self.player.on('vpEvent', self.VPMeasurement);
            });
        };

        let fetchUrl = `https://host.vpplayer.tech/player/${configCNC.video.playerId}/${configCNC.video.mediaId}`;
        fetchUrl += projectId === 'agmipnzu' ? '/smyrfcjo.json' : '.json'; // Podmínka pro MallTVOlympics.

        fetch(fetchUrl)
            .then((response) => response.json())
            .then((data) => {
                utilsConsole('data', data);

                // disable ads for paying users
                if (configVpPlayer.video.advertising === false) {
                    data.video.advertising = false;
                }
                addVpEvent(data);
            });
    };

    /**
     * zjistime si jestli mame reklamu ktera je hned na zacatku videa, abychom neodesilali player_event pred reklamou
     * @param videoObject
     */
    self.checkFirstAd = (videoObject) => {
        utilsConsole('checkFirstAd', videoObject.ads);
        // pokud mame ve videoObjektu ads a v nem adBreaks
        // projdeme si ho a najdem reklamu s breakTimingValue 0, tzn zacina hned
        if ('ads' in videoObject && 'adBreaks' in videoObject.ads) {
            const ad = videoObject.ads.adBreaks.find(
                (entry) => entry.breakTimingValue === 0 && entry.breakType === 'preroll',
            );
            if (typeof ad !== 'undefined') {
                prerollAd = true;
            }
        } else {
            prerollAd = false;
        }
    };

    // zjistime si jestli aktualni video je auto play
    self.getVideoAutoPlay = () =>
        self.player.playlistVideoIndex === 0 ? self.player.autoStart : self.player.isAutoplay;

    // zjisteni magazinu ze ktereho je aktualni video ktere zrovna hraje
    self.getVideoMagazineName = () => {
        const { videoProjectId } = self.player.fullConfiguration;

        if (videoProjectId && videoProjectId in vpProjectsIds) {
            return vpProjectsIds[videoProjectId];
        }

        return videoProjectId;
    };

    /**
     * Muzeme zjistit teprve po initu vpPlayeru
     *
     * @returns {string}
     */
    self.getPlayerType = () =>
        `${configCNC.video.miniplayer ? 'Mini player' : 'Normal'}${
            self.getVideoAutoPlay() ? ' autoplay' : ''
        }`;

    /**
     * @description - obsluha nastaveneho cuePointu v 10s - stopnuti videa a zruseni autoplaye
     */

    /**
     * @description - obsluha eventu - VPAP a měření
     * @param {event} evt
     *
     */
    self.VPMeasurement = (evt) => {
        if (evt) {
            utilsConsole(`VPMeasurment ${evt.eventName}`, evt.currentTime);
            // nastavime si cas pro progressCuepoint
            const progressTime = Math.ceil(evt.currentTime / 10) * 10;
            utilsConsole(
                `VPMeasurement > evt.eventName ${evt.eventName} | evt.currentTime ${evt.currentTime}`,
                evt,
            );

            const magazineName = self.getVideoMagazineName();

            switch (evt.eventName) {
                case 'playlistItem':
                    if (typeof self.player.playlistVideoIndex === 'undefined') {
                        return;
                    }

                    // vynulujeme si cuepointy u noveho videa
                    progressCuePointsTimes.length = 0;

                    if (self.player.playlistVideoIndex > 0) {
                        // jen u playlistu
                        self.sendAppService('sentPlayerData', {
                            eventType: 'player_start',
                            time: evt.currentTime,
                            clear: true,
                        });
                    }
                    break;
                case 'ready':
                    utilsConsole(' self.currentVideo', self.currentVideo);
                    configCNC.eventPlaySend = false;
                    self.playTime = 0;
                    self.adPlayTime = 0;
                    self.firstInteraction = false;
                    self.adLoad = false;

                    gemiusSetting.videoMagazine = magazineName;
                    gemiusSetting.playertype = self.getPlayerType();

                    self.gemiusService = new GemiusService({
                        gemius: gemiusSetting,
                        video: self.currentVideo,
                    });

                    // aktualni video co nam hraje
                    currentVideo = self.player.videoObject;
                    utilsConsole(`currentVideo >>> `, currentVideo, self.player);
                    // zjistime si jak na tom jsou reklamy jestli mame preroll s casem 0
                    self.checkFirstAd(currentVideo);

                    // nastavime si object pro web app podle aktualniho videa
                    // pro prvni vide jiz mame ale v budoucnu bude lepsi to nastavit zde
                    webAppConfig.video.magazineName = magazineName;
                    webAppConfig.video.id = currentVideo.videoId; // pro ring video ID -> configCNC.video.id;

                    if (self.player.playlistVideoIndex > 0) {
                        // az bude v aktualnim videu duration zatim tam je prazdne
                        // webAppConfig.video.durationSeconds = currentVideo.duration;
                        const index = self.player.playlistVideoIndex;

                        webAppConfig.video.durationSeconds = parseInt(
                            self.player.playlist.videos[index].duration,
                            10,
                        );

                        webAppConfig.video.title = currentVideo.title;
                        webAppConfig.video.source = currentVideo.author;
                    } else if (self.player.playlistVideoIndex === 0) {
                        // pokud hraje prvni video nastavime podle configu
                        // hraje pokud se prejde na konec playlistu klikne se na opakovat
                        webAppConfig.video.durationSeconds = parseInt(
                            configCNC.video.durationSeconds,
                            10,
                        );
                        webAppConfig.video.title = configCNC.video.title;
                        webAppConfig.video.source = configCNC.video.source;
                        webAppConfig.video.mute = self.player.config.muted;
                    }

                    // novy app service pro video co je prave ready
                    self.appWebService = new AppWebService(webAppConfig);
                    // odesleme start
                    self.sendAppService('sentPlayerData', {
                        eventType: 'player_start',
                        time: evt.currentTime,
                        clear: true,
                    });
                    // vynulujeme si cuepointy na zacatku prehravani
                    progressCuePointsTimes.length = 0;
                    self.setAppWebCuePoints();
                    break;
                case 'twenty-view':
                    self.sendAppService('sentPlayerEventWithTime', {
                        eventType: 'player_progress_20',
                        timePosition: 20,
                    });
                    break;
                case 'firstFrame':
                    self.player.complete = false;
                    break;
                case 'pause':
                    if (evt.reason && evt.reason === 'user-interaction') {
                        if (
                            typeof self.gemiusService !== 'undefined' &&
                            typeof self.gemiusService.sendHit !== 'undefined'
                        ) {
                            self.gemiusService.sendHit(evt.currentTime, 'pause');
                        }
                        self.sendAppService('sentPlayerEventWithTime', {
                            eventType: 'player_pause',
                            timePosition: evt.currentTime,
                        });
                    }
                    break;
                case 'play':
                    // pokud nemame gemius vytvorime si ho
                    if (
                        typeof self.gemiusService !== 'undefined' &&
                        typeof self.gemiusService.sendHit !== 'undefined'
                    ) {
                        self.gemiusService.sendHit(evt.currentTime, 'play');
                    }

                    if ((evt.autoStart || evt.begin) && !configCNC.eventPlaySend) {
                        // pokud nemame na zacatku videa preroll zacina hrat hned video posleme player_play
                        utilsConsole('prerollAd', prerollAd);

                        if (!prerollAd) {
                            self.sendPlayEvent(evt.currentTime);
                        }
                    } else {
                        // kazdy dalsi event play
                        if (
                            !self.firstInteraction &&
                            evt.afterPause &&
                            Math.floor(evt.currentTime) === 10
                        ) {
                            self.sendAppService('sentPlayerEventWithTime', {
                                eventType: 'player_first_interaction',
                                timePosition: evt.currentTime,
                                interaction: 'user_play_10s',
                                clear: true,
                            });
                            self.firstInteraction = true;
                        }

                        // pokud jsme neodesilali event palyer_play a zaroven mame preroll odesleme ho
                        if (prerollAd && !configCNC.eventPlaySend) {
                            self.sendPlayEvent(evt.currentTime);
                        }

                        // player_resume nemusime posilat na zacatku prehravani
                        const currentTime = Math.floor(evt.currentTime);
                        if (currentTime > 0) {
                            self.sendAppService('sentPlayerEventWithTime', {
                                eventType: 'player_resume',
                                timePosition: currentTime,
                            });
                        }
                    }
                    break;
                case 'vp-request-play':
                    if (!self.firstInteraction && evt.interaction) {
                        self.sendAppService('sentPlayerEventWithTime', {
                            eventType: 'player_first_interaction',
                            timePosition: self.player.video.currentTime,
                            interaction: 'user_play_10s',
                            clear: true,
                        });
                        self.firstInteraction = true;
                    }
                    break;
                case 'seek':
                    self.sendAppService('sentPlayerEventWithTime', {
                        eventType: 'player_seeking',
                        timePosition: evt.currentTime,
                    });
                    break;
                case 'seeked':
                    self.setPlayerProgress(progressTime);
                    if (
                        typeof self.gemiusService !== 'undefined' &&
                        typeof self.gemiusService.sendHit !== 'undefined'
                    ) {
                        self.gemiusService.sendHit(evt.currentTime, 'seek');
                    }
                    break;

                case 'bufferFull':
                    if (evt.currentTime > 0) {
                        self.sendAppService('sentPlayerEventWithTime', {
                            eventType: 'player_buffering',
                            timePosition: evt.currentTime,
                        });
                    }
                    break;
                case 'float':
                    self.sendAppService('setPlayerProperty', {
                        propertyName: 'playerVariant',
                        propertyVal: evt.floating === true ? 'floating' : 'standard',
                    });
                    self.sendAppService('sentPlayerVariant', {
                        key: 'playerVariant',
                        value: evt.floating === true ? 'floating' : 'standard',
                        time: evt.currentTime,
                    });

                    break;
                case 'time':
                    utilsConsole('VPMeasurement > time', evt);

                    // poku bychom meli ve videu preroll ale ten by se nenacetl tak pro jistotu se pokusime odeslat player_play event
                    self.sendPlayEvent(0);
                    break;
                case 'mute':
                    if (!self.firstInteraction) {
                        self.sendAppService('sentPlayerEventWithTime', {
                            eventType: 'player_first_interaction',
                            timePosition: evt.currentTime,
                            interaction: 'user_sound',
                            clear: true,
                        });
                        self.firstInteraction = true;
                    }
                    self.sendAppService('sentPlayerSetting', {
                        eventType: 'player_settings_change_mute',
                        item: 'mute',
                        itemValue: evt.muted,
                        time: evt.currentTime,
                    });
                    break;
                case 'adMute':
                    if (!self.firstInteraction) {
                        self.sendAppService('sentPlayerEventWithTime', {
                            eventType: 'player_first_interaction',
                            timePosition: evt.currentTime,
                            interaction: 'user_sound',
                            clear: true,
                        });
                        self.firstInteraction = true;
                    }
                    self.sendAppService('sentAdPlay', {
                        eventType: 'player_ads_mute',
                        item: 'mute',
                        id: adPlayId,
                        itemValue: evt.muted,
                        time: evt.currentTime,
                    });
                    break;
                case 'fullscreen':
                    if (
                        typeof self.gemiusService !== 'undefined' &&
                        typeof self.gemiusService.sendHit !== 'undefined'
                    ) {
                        self.gemiusService.sendHit(evt.currentTime, 'chngRes');
                    }

                    if (!self.firstInteraction) {
                        self.sendAppService('sentPlayerEventWithTime', {
                            eventType: 'player_first_interaction',
                            timePosition: evt.currentTime,
                            interaction: 'user_full_screen',
                            clear: true,
                        });
                        self.firstInteraction = true;
                    }
                    self.sendAppService('sentPlayerSetting', {
                        eventType: 'player_settings_change_fullscreen',
                        item: 'fullscreen',
                        itemValue: evt.fullscreen,
                        timePosition: evt.currentTime,
                    });
                    break;
                case 'levelsChanged':
                    self.sendAppService('sentPlayerSetting', {
                        eventType: 'player_settings_change_resolution',
                        item: 'resolution',
                        itemValue: evt.level,
                        timePosition: evt.currentTime,
                    });
                    break;
                case 'beforeComplete':
                    self.sendAppService('sentPlayerEventWithTime', {
                        eventType: 'player_end',
                        timePosition: evt.currentTime,
                    });
                    break;
                case 'complete':
                    if (
                        typeof self.gemiusService !== 'undefined' &&
                        typeof self.gemiusService.sendHit !== 'undefined'
                    ) {
                        self.gemiusService.sendHit(evt.currentTime, 'complete');
                    }
                    break;
                case 'cast':
                    self.sendAppService('sentPlayerSetting', {
                        eventType: 'player_settings_change_chromecast',
                        item: 'cast',
                        itemValue: evt.active,
                        timePosition: evt.currentTime,
                    });
                    break;
                case 'adImpression':
                    self.gemiusService.setAdVideoObject(
                        evt.adDuration,
                        evt.adTitle,
                        evt.adId,
                        evt.adPosition,
                    );
                    self.adLoad = true;
                    adPlayId = evt.adPlayId;
                    self.sendAppService('sentAdList', {
                        eventType: 'player_ads_loaded',
                        id: evt.adPlayId,
                        length: evt.adDuration,
                        skipAfter: config.settingsAdSkippable,
                        type: 'video',
                        slot: evt.adPosition,
                        adPosition: 1,
                    });
                    self.sendAppService('sentAdPlay', {
                        eventType: 'player_ads_play',
                        id: evt.adPlayId,
                        timePosition: evt.currentTime,
                    });
                    self.adLoad = true;
                    self.adPlayTime = 0;
                    break;
                case 'adClick':
                    self.sendAppService('sentAdPlay', {
                        eventType: 'player_ads_click',
                        id: evt.adId,
                        timePosition: evt.currentTime,
                    });
                    break;
                case 'adPause':
                    self.gemiusService.sendAdHit(evt.currentTime, 'pause', evt.adPlayId);
                    self.sendAppService('sentAdPlay', {
                        eventType: 'player_ads_pause',
                        id: evt.adPlayId,
                        timePosition: evt.currentTime,
                    });
                    break;
                case 'adPlay':
                    self.gemiusService.sendAdHit(evt.currentTime, 'play', evt.adPlayId);
                    if (self.adLoad) {
                        self.sendAppService('sentAdPlay', {
                            eventType:
                                evt.afterPause === true ? 'player_ads_resume' : 'player_ads_play',
                            id: evt.adPlayId,
                            timePosition: evt.currentTime,
                        });
                    }
                    break;
                case 'adSkippable':
                    self.sendAppService('sentAdPlay', {
                        eventType: 'player_ads_skippable',
                        id: evt.adPlayId,
                        position: evt.currentTime,
                    });
                    break;
                case 'adSkipped':
                    self.sendAppService('sentAdPlay', {
                        eventType: 'player_ads_skip',
                        id: evt.adPlayId,
                        timePosition: 1,
                    });
                    break;
                case 'adComplete':
                    self.gemiusService.sendAdHit(evt.currentTime, 'complete', evt.adPlayId);
                    self.sendAppService('sentAdPlay', {
                        eventType: 'player_ads_end',
                        id: evt.adPlayId,
                        timePosition: evt.currentTime,
                    });
                    self.adLoad = false;
                    break;
                case 'adTime':
                    setTimeout(self.adProgress, 0, {
                        actualAdTime: evt.currentTime,
                        oldAdTime: self.adPlayTime,
                        adId: evt.adPlayId,
                    });
                    break;
                case 'vp-request-replay':
                    // vynulujeme si cuepointy pri replay, proto ze se neprenesou do repalye vide
                    progressCuePointsTimes.length = 0;
                    self.setAppWebCuePoints();
                    break;
                case 'videoLocked':
                    self.lockedInTimeOverlay(configCNC.video.elementId);
                    break;
                default:
            }
        }
    };

    /**
     * @description - funkce pro zobrazení vrstvy při zamčeném videu v určitém čase
     *
     * @param {string} elementID - selektor prehravace
     */
    self.lockedInTimeOverlay = (elementID) => {
        const videoPlayerElement = document.getElementById(`${elementID}`);
        const overlayElement = document.getElementById(`overlay-${elementID}`);
        if (overlayElement) {
            // Add container inline-size for floating miniplayer
            videoPlayerElement.style.containerType = 'inline-size';

            const isPlayerFloating = self.player.isFloating();

            if (isPlayerFloating) {
                // Append overlay element to video player if floating
                videoPlayerElement.appendChild(overlayElement);
            }

            // Make overlay visible
            overlayElement.style.visibility = 'visible';
        }
    };

    /**
   * @description - funkce pro odesílání události player_ads_progress do dataLayeru každých 5s
   *
   * @param {object} adTimeData - objekt s časovými údaji a s id přehrávabé reklamy
   * @param {number} adTimeData.actualAdTime - aktuální čas přehrávané reklamy (s)
   * @param {number} adTimeData.oldAdTime - čas uložený při posledním odeslání události do objectu playeru

   * @param {string} adTimeData.adPlayId - id přehrávabé reklamy
   */
    self.adProgress = (adTimeData) => {
        const actualAdTime = Math.round(adTimeData.actualAdTime);
        const playAdProgress = actualAdTime - adTimeData.oldAdTime;

        if (playAdProgress > 9) {
            self.sendAppService('sentAdPlay', {
                eventType: 'player_ads_progress',
                id: adTimeData.adId,
                timePosition: actualAdTime,
            });
            self.adPlayTime = actualAdTime;
        }
    };

    /**
     * @description - funkce pro odesílání informace o chybě do logování
     * @param {object} info - object s údaji o chybě, které event error vrací
     */
    self.sentErrorInfo2Url = (info) => {
        const baseUrl = 'http://www.blesk.cz/errorpage?log_error';
        const url =
            `${baseUrl}&msg=${info.message || ''}&code=${info.code || ''}&player=VP player` +
            `&details=${info.sourceError.details || ''}&response=${info.sourceError.response || ''}`;

        fetch(url, {
            method: 'POST',
            mode: 'no-cors',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
    };

    /**
     * @description - funkce pro odesílání události player_progress do dataLayeru každých 10s
     *
     * @param time
     */
    self.sendPlayerProgress = (time) => {
        utilsConsole(`date time >>> ${time}`);
        self.sendAppService('sentPlayerEventWithTime', {
            eventType: 'player_progress',
            timePosition: time,
        });
        const newTime = time + 10;
        self.setPlayerProgress(newTime);
    };

    /**
     * nastaveni cue point pro odeslani player_propgress
     *
     * @param time
     */
    self.setPlayerProgress = (time) => {
        utilsConsole(`setPlayerProgress time >>> ${time}`, progressCuePointsTimes);

        // pokud event jiz mame nastaveny dale nenastavujeme
        if (!progressCuePointsTimes.includes(time)) {
            utilsConsole('setPlayerProgress nastavujeme');
            // nastavime se cat pro dalsi cue point kdy odesleme progress
            self.player.setCuePoint(time, () => {
                self.sendPlayerProgress(time);
            });
            utilsConsole('!!! setPlayerProgress !!!');
            progressCuePointsTimes.push(time);
        }
    };

    /**
     * Odeslani player_play eventu
     * @type {{self, configCNC}}
     */
    self.sendPlayEvent = (time) => {
        if (!configCNC.eventPlaySend) {
            configCNC.eventPlaySend = true;
            self.sendAppService('sentPlayerEventWithTime', {
                eventType: 'player_play',
                timePosition: time,
            });
        }
    };

    /**
     * @description - funkce nastaví časové body pro posílání eventů  player_progress_10,player_progress_20 a quartil 25, 50, 70
     */
    self.setAppWebCuePoints = () => {
        utilsConsole('setAppWebCuePoints', self.player.video.duration);

        const quartile25 = Math.round(self.player.video.duration * 0.25);
        const quartile50 = Math.round(self.player.video.duration * 0.5);
        const quartile75 = Math.round(self.player.video.duration * 0.75);

        utilsConsole(`currentVideo `, self.player.video);

        // nastavime si cuepointy na odesilani progressu 25 50 a 75%
        self.player.setCuePoint(quartile25, () => {
            self.sendAppService('sentPlayerEventWithTime', {
                eventType: 'player_quartile_25',
                timePosition: quartile25,
            });
        });
        self.player.setCuePoint(quartile50, () => {
            self.sendAppService('sentPlayerEventWithTime', {
                eventType: 'player_quartile_50',
                timePosition: quartile50,
            });
        });
        self.player.setCuePoint(quartile75, () => {
            self.sendAppService('sentPlayerEventWithTime', {
                eventType: 'player_quartile_75',
                timePosition: quartile75,
            });
        });
        // nastavime si prvni odeslani eventu player_progress
        self.setPlayerProgress(10);
    };

    window.cncGjvpPlayers.push(self);
    return self;
}
