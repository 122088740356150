export const vpProjectsIds = {
    agmipnyk: 'Auto',
    agmipnym: 'Isport',
    agmipnyn: 'ABC',
    agmipnyo: 'Ahaonline',
    agmipnyp: 'Autotip',
    agmipnyq: 'Blesk',
    agmipnyr: 'cncenter.cz',
    agmipnys: 'E15',
    agmipnyt: 'Recepty.cz',
    agmipnyu: 'blog.mall.cz',
    agmipnyv: 'blog.mall.sk',
    agmipnyw: 'Mojezdravi',
    agmipnyx: 'recepty.blesk.cz',
    agmipnyy: 'Reflex',
    agmipnyz: 'Svetmotoru.cz',
    agmipnza: 'TV program',
    agmipnzb: 'Video portal',
    agmipnzc: 'Ženy.cz',
    agmipnzd: 'Živě',
    agmipnzu: 'MallTVOlympics',
};

export const gemiusConfig = () => {
    const magazine = [
        {
            ids: ['17', '104', '106', '107', '108', '113'],
            realId: '1',
        },
        {
            ids: ['202', '203', '204', '205', '206', '211', '212', '3601', '3602', '3603', '3604'],
            realId: '2',
        },
        {
            ids: ['301'],
            realId: '3',
        },
        {
            ids: ['1101'],
            realId: '11',
        },
        {
            ids: ['1201'],
            realId: '12',
        },
    ];

    const defaultSetting = {
        typology: 'not-set',
        series: 'video',
        identifier: 'bac6P47Qpzf8KzcCXd4594ZRDovFRkOnIW4ZHK5pkvH.07',
    };

    const gemiusSetting = {
        magazine99: {
            typology: 'Blesk/TV Blesk',
            series: 'video',
        },
        magazine96: {
            typology: 'Mall-SK',
            series: 'video',
        },
        magazine97: {
            typology: 'Mall',
            series: 'video',
        },
        magazine8: {
            typology: 'intranet.cz',
            series: 'video',
        },
        magazine4: {
            typology: 'Blesk',
            series: 'video',
        },
        magazine3303: {
            typology: 'Blesk/TV Blesk',
            series: 'video',
        },
        magazine3302: {
            typology: 'Blesk/Blesk pro ženy/TV Blesk pro ženy',
            series: 'video',
        },
        magazine3301: {
            typology: 'Isport/TV Isport',
            series: 'video',
        },
        magazine33: {
            typology: 'Blesk/TV Blesk',
            series: 'video',
        },
        magazine3: {
            typology: 'Reflex',
            series: 'video',
        },
        magazine2SR: {
            typology: 'Isport/Sportrevue.cz',
            series: 'video',
        },
        magazine29: {
            typology: 'Auto',
            series: 'video',
        },
        magazine27: {
            typology: 'Recepty.cz',
            series: 'video',
        },
        magazine26: {
            typology: 'Dáma',
            series: 'video',
        },
        magazine25MM: {
            typology: 'Mobilmania',
            series: 'video',
        },
        magazine25DP: {
            typology: 'Živě/Doupě',
            series: 'video',
        },
        magazine25CO: {
            typology: 'Živě/Connect.cz',
            series: 'video',
        },
        magazine25: {
            typology: 'Živě',
            series: 'video',
        },
        magazine24: {
            typology: 'Maminka',
            series: 'video',
        },
        magazine21GS: {
            typology: 'Autorevue/MotoGPSport',
            series: 'video',
        },
        magazine21F1: {
            typology: 'Auto/F1Sport',
            series: 'video',
        },
        magazine21AR: {
            typology: 'Autorevue',
            series: 'video',
        },
        magazine21: {
            typology: 'Mojezdraví',
            series: 'video',
        },
        magazine20: {
            typology: 'Ženy.cz',
            series: 'video',
        },
        magazine2: {
            typology: 'Isport',
            series: 'video',
        },
        magazine2ES: {
            typology: 'Isport/grunex',
            series: 'video',
        },
        magazine19: {
            typology: 'Blesk',
            series: 'video',
        },
        magazine18: {
            typology: 'Ahaonline',
            series: 'video',
        },
        magazine11DI: {
            typology: 'Živě/DIGIarena',
            series: 'video',
        },
        magazine11AV: {
            typology: 'Živě/AVmania',
            series: 'video',
        },
        magazine114: {
            typology: 'Maminka/Supermamy',
            series: 'video',
        },
        magazine11: {
            typology: 'E15',
            series: 'video',
        },
        magazine103: {
            typology: 'Blesk/Blesk pro ženy/Hobby',
            series: 'video',
        },
        magazine102: {
            typology: 'Blesk/Blesk pro ženy',
            series: 'video',
        },
        magazine101: {
            typology: 'Blesk/Blesk pro ženy/Horoskopy',
            series: 'video',
        },
        magazine1: {
            typology: 'Blesk',
            series: 'video',
        },
        magazine113: {
            typology: 'Blesk',
            series: 'video',
        },
        magazine5: {
            typology: 'ABC',
            series: 'video',
        },
        'magazine1-live': {
            typology: 'Blesk',
            series: 'Online Stream',
        },
        'magazine2-live': {
            typology: 'Isport',
            series: 'Online Stream',
        },
        'magazine3301-live': {
            typology: 'Isport/TV Isport',
            series: 'Online Stream',
        },
        'magazine3303-live': {
            typology: 'Blesk/TV Blesk',
            series: 'Online Stream',
        },
    };

    const getGemiusSetting = (magazineId, gemius) => {
        const realId = magazine.find((item) => item.ids.includes(magazineId));

        let setting = realId
            ? gemiusSetting[`magazine${realId.realId}`]
            : gemiusSetting[`magazine${magazineId}`];

        if (setting) {
            setting.identifier = defaultSetting.identifier;
        } else {
            setting = defaultSetting;
        }

        const result = Object.keys(gemius)
            .concat(Object.keys(setting))
            .reduce((obj, key) => {
                if (!(key in obj)) {
                    if (gemius[key] && gemius[key] !== undefined && gemius[key] !== '') {
                        obj[key] = gemius[key];
                    } else {
                        obj[key] = setting[key];
                    }
                }
                return obj;
            }, {});

        return result;
    };

    return { getGemiusSetting };
};
