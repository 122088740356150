import * as GAM from '../ads/GAM';

/**
 *
 * @description VP Player create json config file
 * @author Milan Machacek <milan1.machacek@cncenter.cz>
 * @version 0.0.7
 * @create 2019-07-15
 */

export function SetVpObj() {
    const self = this;

    /** @description -  nastaveni reklamy googima
     * @param {object} adsConfig - nastaveni reklamy prevzate z cncVideoConfig.js
     * @param {object} options - dalsi nastaveni potrebna pro vytvoreni adrequestu
     * @param {boolean} options.miniplayer  - je prehravac miniplayer?
     * @param {string} options.magazine - kod magazinu
     * @param {string} options.template - oznaceni template pro ads ('smallplayer', 'bigplayer', 'bigplayer_play')
     * @param {number} options.duration - delka videa
     * @param {boolean | string} options.autoplay - nastaveni autostart pro player
     * @param {object} flags - flagy nastavene pro video
     * @param {string} adsVariant - varianta reklamy, napr. 'delay','mid30-desktop'
     * 		 podrobněji popsáno v confluenci zde: https://confluence.cncenter.cz/pages/viewpage.action?pageId=230588467
     *
     * @return {object} out - kompletni nastaveni pro vp GAM.adSchedule
     */

    self.setAdsGAM = (adsConfig, options) => {
        const { magazine, isMiniplayer, template, flags, webasAdsNoDelay, videoKeywords } = options;
        const isFirstVideo = options.firstVideo || false;
        let adsBreak;

        const out = [];
        const isEOM = magazine !== -1 && magazine.indexOf('98') === 0; // EOM = '98' (Extra), '98ON' (OneTv) a '98G' (G.cz)
        const site =
            typeof window.__cncPageDefinition !== 'undefined'
                ? window.__cncPageDefinition.site
                : adsConfig.site.toLowerCase();
        const { area } = adsConfig;

        // brandSafety
        const videoSafety = [];

        Object.values(flags).forEach((itemFlags) => {
            if (itemFlags.group_field === 'brand_safety') {
                videoSafety.push(itemFlags.defname);
            }
        });

        switch (adsConfig.variant) {
            case 'delay':
                if (isMiniplayer || webasAdsNoDelay) {
                    if (adsConfig.preroll1) {
                        adsBreak = GAM.adSchedule('preroll', {
                            area,
                            site,
                            template,
                            target_position: 'preroll_1',
                            videoSafety,
                            videoKeywords,
                        });
                        out.push(adsBreak);
                    }
                    // podminka pro EOM, ktere maji vyyjimku - 1 preroll pro miniplayer.VID-754. Alena

                    if (adsConfig.preroll2 && !(isEOM && isMiniplayer)) {
                        adsBreak = GAM.adSchedule('preroll', {
                            area,
                            site,
                            template,
                            target_position: 'preroll_2',
                            videoSafety,
                            videoKeywords,
                        });
                        out.push(adsBreak);
                    }

                    if (adsConfig.midroll) {
                        for (let i = 1; i <= 30; i += 1) {
                            adsBreak = GAM.adSchedule('midroll', {
                                area,
                                site,
                                template,
                                target_position: 'midroll_1',
                                videoSafety,
                                videoKeywords,
                            });
                            adsBreak.breakTimingType = 'time';
                            adsBreak.breakTimingValue = i * 480;
                            out.push(adsBreak);
                        }
                    }

                    if (adsConfig.postroll) {
                        adsBreak = GAM.adSchedule('postroll', {
                            area,
                            site,
                            template,
                            target_position: 'postroll_1',
                            videoSafety,
                            videoKeywords,
                        });
                        out.push(adsBreak);
                    }
                } else {
                    if (isFirstVideo) {
                        if (adsConfig.preroll1) {
                            adsBreak = GAM.adSchedule('midroll', {
                                area,
                                site,
                                template,
                                target_position: 'preroll_1',
                                videoSafety,
                                videoKeywords,
                            });
                            adsBreak.breakTimingType = 'time';
                            adsBreak.breakTimingValue = 10;
                            out.push(adsBreak);
                        }
                        if (adsConfig.preroll2) {
                            adsBreak = GAM.adSchedule('midroll', {
                                area,
                                site,
                                template,
                                target_position: 'preroll_2',
                                videoSafety,
                                videoKeywords,
                            });
                            adsBreak.breakTimingType = 'time';
                            adsBreak.breakTimingValue = 10;
                            out.push(adsBreak);
                        }
                    } else {
                        if (adsConfig.preroll1) {
                            adsBreak = GAM.adSchedule('preroll', {
                                area,
                                site,
                                template,
                                target_position: 'preroll_1',
                                videoSafety,
                                videoKeywords,
                            });
                            out.push(adsBreak);
                        }

                        if (adsConfig.preroll2) {
                            adsBreak = GAM.adSchedule('preroll', {
                                area,
                                site,
                                template,
                                target_position: 'preroll_2',
                                videoSafety,
                                videoKeywords,
                            });
                            out.push(adsBreak);
                        }
                    }
                    if (adsConfig.midroll) {
                        for (let i = 1; i <= 30; i += 1) {
                            adsBreak = GAM.adSchedule('midroll', {
                                area,
                                site,
                                template,
                                target_position: 'midroll_1',
                                videoSafety,
                                videoKeywords,
                            });
                            adsBreak.breakTimingType = 'time';
                            adsBreak.breakTimingValue = i * 480;
                            out.push(adsBreak);
                        }
                    }

                    if (adsConfig.postroll) {
                        adsBreak = GAM.adSchedule('postroll', {
                            area,
                            site,
                            template,
                            target_position: 'postroll_1',
                            videoSafety,
                            videoKeywords,
                        });
                        out.push(adsBreak);
                    }
                }
                break;
            default:
        }
        return out;
    };
}
