/** @description zakladni nastaveni a funkce pro GAM reklamu ve videoplayeru
 * @description bali se s ostatnimi scripty pro videoplayer
 * @author  kód: inzerce, Ivan Mihalic, 2020
 * @author  implementace do videoplayeru + popis : Alena Marečková, 2020
 */

/* pole pro stanoveni hodnot L2 */
/**
 * @description pole pro stanovení hodnot L2 do hlavní funkce adSchedule
 * @type {array} *
 */
export const siteToL2 = {
    abicko: 'cnc260',
    ahaonline: 'cnc260',
    auto: 'cnc260',
    autorevue: 'cnc260',
    blesk: 'cnc260',
    bleskprozeny: 'cnc260',
    dama: 'cnc260',
    e15: 'cnc260',
    eleague: 'cnr260',
    evropa2: 'cnr260',
    extra: 'cnr260',
    extralife: 'cnr260',
    extrastory: 'cnr260',
    frekvence1: 'cnr260',
    gcz: 'cnr260',
    hledejceny: 'cnc260',
    hokej: 'cnr260',
    info: 'cnr260',
    isport: 'cnc260',
    lideazeme: 'cnc260',
    lifee: 'cnr260',
    malltv: 'cnr260',
    maminka: 'cnc260',
    mimibazarcz: 'cnc260',
    mimibazarsk: 'cnc260',
    mobilmania: 'cnc260',
    mojezdravi: 'cnc260',
    nhl: 'cnr260',
    onetv: 'cnr260',
    recepty: 'cnc260',
    reflex: 'cnc260',
    sparta: 'cnr260',
    sportrevue: 'cnc260',
    toprecepty: 'cnr260',
    youradio: 'cnr260',
    zeny: 'cnc260',
    zive: 'cnc260',
};

/**
 * @description funkce pro ziskani parametru z URL
 * @return {string} retezec s hodnotami z url oddelenymi carkou
 */
// eslint-disable-next-line no-unused-vars
export function getUrlTargeting() {
    const urlExclusion = ['', 'clanek'];
    const tempArray = window.location.pathname.split('/');

    const pathArr = tempArray.filter((entry) => !urlExclusion.includes(entry));

    return pathArr ? pathArr.join(',') : '';
}

/**
 * @description funkce pro ziskani klicovych slov
 * @description funkce pracuje s klicovymi slovy clanku, ve kterem je video
 * @description funkce je ziskava z globalniho objektu __cncPageDefinition, z meta keywords ve strance a y klicovzch slov videa
 * @return {string} nepojmenovany retezec s klicovymi slovy oddelenymi carkou
 */
// eslint-disable-next-line no-unused-vars
export function getKeywords(keywords4Video) {
    let categoryTree;
    let categoryId;
    const keywordsArr = [];
    let tempKeywords;
    let keywords;

    /* ke keywords se pridava cislo kategorie clanku ve tvaru IDCATEGORY*** - hvezdicky je cislo, carka oddelovac
	viz VID-807.
	 */
    if (window && window.__cncPageDefinition && window.__cncPageDefinition.categoryTree) {
        categoryTree = window.__cncPageDefinition.categoryTree.split(',');
        categoryId = `,IDCATEGORY${categoryTree.pop()}`;
    } else {
        categoryId = '';
    }

    if (
        window &&
        window.__cncPageDefinition &&
        window.__cncPageDefinition.keywords &&
        Array.isArray(window.__cncPageDefinition.keywords)
    ) {
        keywords = window.__cncPageDefinition.keywords.join(',');
    }

    if (document.querySelector('meta[name="keywords"]')) {
        const keywordsMeta = document.querySelector('meta[name="keywords"]');

        if (typeof keywordsMeta === 'string') {
            tempKeywords = keywordsMeta.split(',');
            let keyword = '';
            for (let k = 0; k < tempKeywords.length; k += 1) {
                keyword = tempKeywords[k];
                if (typeof keyword === 'string') {
                    keyword = keyword.trim();
                    if (keyword !== '' && !keywordsArr.includes(keyword)) {
                        keywordsArr.push(keyword);
                    }
                }
            }
        }
    }

    if (typeof keywords4Video === 'string') {
        tempKeywords = keywords4Video.split(',');
        let keyword = '';
        for (let k = 0; k < tempKeywords.length; k += 1) {
            keyword = tempKeywords[k];
            if (typeof keyword === 'string') {
                keyword = keyword.trim();

                if (keyword !== '' && !keywordsArr.includes(keyword)) {
                    keywordsArr.push(keyword);
                }
            }
        }
    }
    return `${keywords},${keywordsArr.join(',')}${categoryId}`;
}

/**
 * @description funkce pro získání Permutive dat
 * zkousi nacist hodnotu z localstorage _pdfps
 * @return {string} retezec obsahujici prvnich 250 znaku s localStorage, parsovany jako json, encodovany
 */
// eslint-disable-next-line no-unused-vars
export function getPermutive() {
    let permutive = '';
    if (localStorage._pdfps) {
        try {
            permutive = encodeURIComponent(
                JSON.parse(localStorage._pdfps || '[]')
                    .slice(0, 250)
                    .join(','),
            );
        } catch (e) {
            /* eslint-disable no-console */
            console.error(e);
            /* eslint-enable no-console */
        }
    }
    return permutive;
}

/**
 * @description funkce pro získání Bisko DMP dat
 * @return {string} - cxSegments nacteny z localStorage
 */
// eslint-disable-next-line no-unused-vars
export function getBisko() {
    let cxSegments = '';
    try {
        cxSegments = localStorage.cxSegments || '';
    } catch (e) {
        e.message = 'error cxSegments';
        // throw new Error(e);
        /* eslint-disable no-console */
        console.error(e);
        /* eslint-enable no-console */
    }
    return cxSegments;
}

/**
 * @description funkce pro získání brand safety dat
 * @description funkce nacita brand safety z globalniho objektu  _cncPageDefinition a kombinuje je
 * s případnými brandsafety videa
 *
 * @param { Array<string> } videoSafety - brand safety videa
 *
 * @return {string} retezec obsahujici hodnoty unikatni safety oddelene carkou
 */
// eslint-disable-next-line no-unused-vars
export function getBrandSafety(videoSafety) {
    let safetyPage;
    if (
        window &&
        window.__cncPageDefinition &&
        window.__cncPageDefinition.safety &&
        Array.isArray(window.__cncPageDefinition.safety)
    ) {
        safetyPage = window.__cncPageDefinition.safety;
    } else {
        safetyPage = [];
    }

    const brandSafety = [...new Set([...safetyPage, ...videoSafety])];
    return brandSafety.join(',');
}

/**
 * @description hlavní funkce pro reklamu GAM do videoplayeru
 * @param {"pre" | "post" |string} offset - pozice reklamy (pre = preroll, post = postroll, hodnota v procentech = midroll)
 * @params {object} params
 * @property {string} params.site -  z window.__cncPageDefinition, pokud neni tak z  ads.site ve cncVideoConfig.js
 * @property {string} params.area - z ads.area ve cncVideoConfig.js - !!! aktualne se nepouziva, area je vzdy 'ostatni'
 * @property {"smallplayer" | "bigplayer_play" | "bigplayer" } params.template - urceni template z hodnot miniplayer a autoplay
 * @property {"preroll_1" | "preroll_2" | "postroll_1" | "midroll_1" } params.target_position - hodnota dle hodnoty offset
 *
 * @return {object} - nepojmenovaný objekt s nastavením pro reklamu
 * @property {string} breakType - hodnota parametru offset předaného funkci
 * @property {string} adTagUrl - kompletně složená tag url pro reklamu

 */
// eslint-disable-next-line no-unused-vars
export function adSchedule(offset, params) {
    // pokud nejsou params tak nelze vytvorit reklamni blok
    if (!offset) {
        return null;
    }

    let site = params && params.site ? params.site : null;
    /* VID-821 */
    if (typeof site === 'string') {
        site = site.replace('_mobile', '');
        // VID-882
        if (site === 'g') {
            site = 'gcz';
        }
    }

    const cncPageDefinition = window ? window.__cncPageDefinition : {};

    // area bude vzdycky ostatni krome blesk-zpravy
    const area =
        cncPageDefinition &&
        cncPageDefinition.forceArea === 'zpravy' &&
        cncPageDefinition.site === 'blesk'
            ? 'zpravy'
            : 'ostatni';

    let adUnit = ['/21869710533'];
    const L2 = siteToL2[site] || null;
    const videoSafety = params.videoSafety || [];
    const keywords4Video = params && params.videoKeywords ? params.videoKeywords : null;
    const custParams = {
        URL: getUrlTargeting(),
        keywords: getKeywords(keywords4Video),
        safety: getBrandSafety(videoSafety),
        permutive: getPermutive(),
        bisko: getBisko(),
        area,
    };

    if (L2) {
        adUnit.push(L2);
        custParams.L2 = L2;

        if (site) {
            adUnit.push(site);
            if (params && params.target_position) {
                adUnit.push(params.target_position);
                if (params && params.template) {
                    adUnit.push(params.template);
                    adUnit.push(area);
                }
            }
        }
    }

    adUnit = adUnit.join('/');

    if (site) {
        custParams.site = site;
    }
    if (params.template) {
        custParams.template = params.template;
    }
    if (params.target_position) {
        custParams.target_position = params.target_position;
    }

    // složení cílící URL (vychazi se z toho co vytvari GAM)
    let tagUrl = 'https://pubads.g.doubleclick.net/gampad/ads';
    const queryParams = {
        iu: adUnit,
        // description_url:encodeURIComponent(''), //sem nevime co davat za URL
        tfcd: '0',
        npa: '0',
        sz: encodeURIComponent(
            ['1920x1080', '1280x720', '1024x576', '768x576', '640x480', '640x360', ''].join('|'),
        ), // povolene rozmery video reklamy
        gdfp_req: '1',
        output: 'vast',
        unviewed_position_start: '1',
        env: 'vp',
        impl: 's',
    };

    // složení URL z queryParams
    const queryParamsArray = [];
    for (let i = 0; i < Object.keys(queryParams).length; i += 1) {
        const item = queryParams[Object.keys(queryParams)[i]];
        queryParamsArray.push([Object.keys(queryParams)[i], item].join('='));
    }
    if (queryParamsArray.length > 0) {
        tagUrl += `?${queryParamsArray.join('&')}`;
    }

    // složení URL z customParams
    const customParamsArray = [];
    for (let i = 0; i < Object.keys(custParams).length; i += 1) {
        const item = custParams[Object.keys(custParams)[i]];
        customParamsArray.push([Object.keys(custParams)[i], item].join('='));
    }

    if (customParamsArray.length > 0) {
        tagUrl += `&cust_params=${encodeURIComponent(customParamsArray.join('&'))}`;
    }

    return {
        breakType: offset,
        adTagUrl: [tagUrl],
    };
}
