export const CncVideoConfig = {
    magazine: [
        {
            ids: ['17', '103', '104', '106', '107', '108', '113'],
            realId: '1',
        },
        {
            ids: ['202', '203', '204', '205', '206', '211', '212', '3601', '3602', '3603', '3604'],
            realId: '2',
        },
        {
            ids: ['301'],
            realId: '3',
        },
        {
            ids: ['1101'],
            realId: '11',
        },
        {
            ids: ['1201'],
            realId: '12',
        },
    ],
    keywords: [
        {
            keyword: 'Štrunc',
            series: 'Štrunc',
            site: 'info',
            area: 'Strunc',
        },
        {
            keyword: 'štrunc',
            series: 'Štrunc',
            site: 'info',
            area: 'Strunc',
        },
        {
            keyword: 'Strunc',
            series: 'Štrunc',
            site: 'info',
            area: 'Strunc',
        },
        {
            keyword: 'strunc',
            series: 'Štrunc',
            site: 'info',
            area: 'Strunc',
        },
        {
            keyword: 'branky body kokoti',
            series: 'Branky body kokoti',
            site: '',
            area: '',
        },
        {
            keyword: 'Branky body kokoti',
            series: 'Branky body kokoti',
            site: '',
            area: '',
        },
        {
            keyword: '15 Otázek',
            series: '15 otázek pro',
            site: '',
            area: '',
        },
        {
            keyword: '15 otázek',
            series: '15 otázek pro',
            site: '',
            area: '',
        },
        {
            keyword: '15 Otazek',
            series: '15 otázek pro',
            site: '',
            area: '',
        },
        {
            keyword: '15 otazek',
            series: '15 otázek pro',
            site: '',
            area: '',
        },
    ],
    categories: [
        {
            id: 4836,
            series: 'Liga',
            site: '',
            area: '1_liga',
        },
        {
            id: 5355,
            series: 'Liga',
            site: '',
            area: '1_liga',
        },
        {
            id: 5356,
            series: 'Liga',
            site: '',
            area: '1_liga',
        },
        {
            id: 5357,
            series: 'Liga',
            site: '',
            area: '1_liga',
        },
        {
            id: 5358,
            series: 'Liga',
            site: '',
            area: '1_liga',
        },
        {
            id: 5359,
            series: 'Liga',
            site: '',
            area: '1_liga',
        },
        {
            id: 5360,
            series: 'Liga',
            site: '',
            area: '1_liga',
        },
        {
            id: 5361,
            series: 'Liga',
            site: '',
            area: '1_liga',
        },
        {
            id: 3858,
            series: 'Premier League',
            site: '',
            area: 'Premier_League',
        },
        {
            id: 6012,
            series: 'La Liga',
            site: '',
            area: 'La_liga',
        },
        {
            id: 6796,
            series: 'Bundes Liga',
            site: '',
            area: 'Bundesliga',
        },
        {
            id: 3849,
            series: 'Hokej ostatní',
            site: '',
            area: '',
        },
        {
            id: 3859,
            series: 'Hokej Extraliga',
            site: '',
            area: '',
        },
        {
            id: 7220,
            series: 'Prostor X',
            site: '',
            area: 'ProstorX',
        },
        {
            id: 8279,
            series: 'Reflexe Kalouska a Stoniše',
            site: '',
            area: 'Reflexe_Kalouska_a_Stonise',
        },
    ],
    config: [
        {
            magazineId: '99',
            magazineName: 'Blesk TV',
            custom: {
                autoplayVersion: 'always-desktop',
                mobileStopAfter: 10,
            },
            ads: {
                area: 'TV_Blesk',
                site: 'Blesk',
                type: 'GAM',
                variant: 'delay',
                mobileAfter: 10,
                desktop: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
                mobile: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
            },
            gemius: {
                typology: 'Blesk/TV Blesk',
                series: '',
                identifier: 'bac6P47Qpzf8KzcCXd4594ZRDovFRkOnIW4ZHK5pkvH.07',
            },
            googleAnalytics: {
                id: '',
                events: [],
            },
            appweb: {
                active: true,
                playerType: 'video',
            },
        },
        {
            magazineId: '96',
            magazineName: 'Mall SK',
            custom: {
                autoplayVersion: 'always-desktop',
                mobileStopAfter: 10,
            },
            ads: {
                area: '',
                site: '',
                type: 'GAM',
                variant: 'delay',
                mobileAfter: 10,
                desktop: {
                    preroll1: false,
                    preroll2: false,
                    postroll: false,
                    midroll: false,
                },
                mobile: {
                    preroll1: false,
                    preroll2: false,
                    postroll: false,
                    midroll: false,
                },
            },
            gemius: {
                typology: 'Mall-SK',
                series: '',
                identifier: 'nAGbG0vHg.0NF_Ed.er0G7QYnDjyb0wy_MUecyhL0cP.k7',
            },
            googleAnalytics: {
                id: '',
                events: [],
            },
        },
        {
            magazineId: '97',
            magazineName: 'Mall CZ',
            custom: {
                autoplayVersion: 'always-desktop',
                mobileStopAfter: 10,
            },
            ads: {
                area: '',
                site: '',
                type: 'GAM',
                variant: 'delay',
                mobileAfter: 10,
                desktop: {
                    preroll1: false,
                    preroll2: false,
                    postroll: false,
                    midroll: false,
                },
                mobile: {
                    preroll1: false,
                    preroll2: false,
                    postroll: false,
                    midroll: false,
                },
            },
            gemius: {
                typology: 'Mall',
                series: '',
                identifier: 'nAGbG0vHg.0NF_Ed.er0G7QYnDjyb0wy_MUecyhL0cP.k7',
            },
            googleAnalytics: {
                id: '',
                events: [],
            },
        },
        {
            magazineId: '8',
            magazineName: 'Intranet',
            ads: {
                area: 'Ostatni',
                site: 'Intranet',
                type: 'GAM',
                desktop: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
                mobile: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
            },
            gemius: {
                typology: 'intranet.cz',
                series: '',
                identifier: 'bac6P47Qpzf8KzcCXd4594ZRDovFRkOnIW4ZHK5pkvH.07',
            },
            googleAnalytics: {
                id: '',
                events: [],
            },
        },
        {
            magazineId: '4',
            magazineName: 'Blesk.cz',
            custom: {
                autoplayVersion: 'always-desktop',
                mobileStopAfter: 10,
            },
            ads: {
                area: 'Ostatni',
                site: 'Blesk',
                type: 'GAM',
                variant: 'delay',
                mobileAfter: 10,
                desktop: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
                mobile: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
            },
            gemius: {
                typology: 'Blesk',
                series: '',
                identifier: 'bac6P47Qpzf8KzcCXd4594ZRDovFRkOnIW4ZHK5pkvH.07',
            },
            googleAnalytics: {
                id: '',
                events: [],
            },
            appweb: {
                active: true,
                playerType: 'video',
            },
        },
        {
            magazineId: '3303',
            magazineName: 'Blesk TV',
            custom: {
                autoplayVersion: 'always-desktop',
                mobileStopAfter: 10,
            },
            ads: {
                area: 'TV_BLESK',
                site: 'Blesk',
                type: 'GAM',
                variant: 'delay',
                mobileAfter: 10,
                desktop: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
                mobile: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
            },
            gemius: {
                typology: 'Blesk/TV Blesk',
                series: '',
                identifier: 'bac6P47Qpzf8KzcCXd4594ZRDovFRkOnIW4ZHK5pkvH.07',
            },
            googleAnalytics: {
                id: 'UA-128524042-1',
                events: {
                    playVideo: {
                        status: true,
                        name: 'video play',
                    },
                    playAd: {
                        status: true,
                        name: 'ad video play',
                    },
                    load: {
                        status: true,
                        name: 'player load',
                    },
                    init: {
                        status: true,
                        name: 'player init',
                    },
                    ready: {
                        status: true,
                        name: 'player ready',
                    },
                    skipAd: {
                        status: true,
                        name: 'skip ad video',
                    },
                    endAd: {
                        status: true,
                        name: 'end ad video',
                    },
                    endVideo: {
                        status: true,
                        name: 'end video',
                    },
                    error: {
                        status: true,
                        name: 'error video',
                    },
                },
            },
            appweb: {
                active: true,
                playerType: 'video',
            },
        },
        {
            magazineId: '3302',
            magazineName: 'Pro \u017eeny TV',
            custom: {
                autoplayVersion: 'always-desktop',
                mobileStopAfter: 10,
            },
            ads: {
                area: 'TV_Bleskprozeny',
                site: 'Bleskprozeny',
                type: 'GAM',
                variant: 'delay',
                mobileAfter: 10,
                desktop: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
                mobile: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
            },
            gemius: {
                typology: 'Blesk/Blesk pro \u017eeny/TV Blesk pro \u017eeny',
                series: '',
                identifier: 'bac6P47Qpzf8KzcCXd4594ZRDovFRkOnIW4ZHK5pkvH.07',
            },
            googleAnalytics: {
                id: '',
                events: [],
            },
            appweb: {
                active: true,
                playerType: 'video',
            },
        },
        {
            magazineId: '3301',
            magazineName: 'iSport TV',
            custom: {
                autoplayVersion: 'always-desktop',
                mobileStopAfter: 10,
            },
            ads: {
                area: 'TV_iSport',
                site: 'iSport',
                type: 'GAM',
                variant: 'delay',
                mobileAfter: 10,
                desktop: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
                mobile: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
            },
            gemius: {
                typology: 'Isport/TV Isport',
                series: '',
                identifier: 'bac6P47Qpzf8KzcCXd4594ZRDovFRkOnIW4ZHK5pkvH.07',
            },
            googleAnalytics: {
                id: '',
                events: [],
            },
            appweb: {
                active: true,
                playerType: 'video',
            },
        },
        {
            magazineId: '33',
            magazineName: 'Blesk TV',
            custom: {
                autoplayVersion: 'always-desktop',
                mobileStopAfter: 10,
            },
            ads: {
                area: 'Ostatni',
                site: 'Blesk',
                type: 'GAM',
                variant: 'delay',
                mobileAfter: 10,
                desktop: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
                mobile: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
            },
            gemius: {
                typology: 'Blesk/TV Blesk',
                series: '',
                identifier: 'bac6P47Qpzf8KzcCXd4594ZRDovFRkOnIW4ZHK5pkvH.07',
            },
            googleAnalytics: {
                id: 'UA-128524042-1',
                events: {
                    playVideo: {
                        status: true,
                        name: 'video play',
                    },
                    playAd: {
                        status: true,
                        name: 'ad video play',
                    },
                    load: {
                        status: true,
                        name: 'player load',
                    },
                    init: {
                        status: true,
                        name: 'player init',
                    },
                    ready: {
                        status: true,
                        name: 'player ready',
                    },
                    skipAd: {
                        status: true,
                        name: 'skip ad video',
                    },
                    endAd: {
                        status: true,
                        name: 'end ad video',
                    },
                    endVideo: {
                        status: true,
                        name: 'end video',
                    },
                    error: {
                        status: true,
                        name: 'error video',
                    },
                },
            },
            appweb: {
                active: true,
                playerType: 'video',
            },
        },
        {
            magazineId: '3',
            magazineName: 'Reflex',
            custom: {
                autoplayVersion: 'always-desktop',
                mobileStopAfter: 10,
            },
            ads: {
                area: 'Ostatni',
                site: 'Reflex',
                type: 'GAM',
                variant: 'delay',
                mobileAfter: 10,
                // 'skipOffset': false,
                desktop: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
                mobile: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
            },
            gemius: {
                typology: 'Reflex',
                series: '',
                identifier: 'bac6P47Qpzf8KzcCXd4594ZRDovFRkOnIW4ZHK5pkvH.07',
            },
            googleAnalytics: {
                id: '',
                events: [],
            },
            appweb: {
                active: true,
                playerType: 'video',
            },
        },
        {
            magazineId: '2SR',
            magazineName: 'Sportrevue',
            custom: {
                autoplayVersion: 'always-desktop',
                mobileStopAfter: 10,
            },
            ads: {
                area: 'Ostatni',
                site: 'Sportrevue',
                type: 'GAM',
                variant: 'delay',
                mobileAfter: 10,
                desktop: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
                mobile: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
            },
            gemius: {
                typology: 'Isport/Sportrevue.cz',
                series: '',
                identifier: 'bac6P47Qpzf8KzcCXd4594ZRDovFRkOnIW4ZHK5pkvH.07',
            },
            googleAnalytics: {
                id: '',
                events: [],
            },
            appweb: {
                active: true,
                playerType: 'video',
            },
        },
        {
            magazineId: '29',
            magazineName: 'Auto.cz',
            custom: {
                autoplayVersion: 'always-desktop',
                mobileStopAfter: 10,
            },
            ads: {
                area: 'Ostatni',
                site: 'Auto',
                type: 'GAM',
                variant: 'delay',
                mobileAfter: 10,
                desktop: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
                mobile: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
            },
            gemius: {
                typology: 'Auto',
                series: '',
                identifier: 'bac6P47Qpzf8KzcCXd4594ZRDovFRkOnIW4ZHK5pkvH.07',
            },
            googleAnalytics: {
                id: '',
                events: [],
            },
            appweb: {
                active: true,
                playerType: 'video',
            },
        },
        {
            magazineId: '27',
            magazineName: 'magazin.recepty.cz',
            custom: {
                autoplayVersion: 'always-desktop',
                mobileStopAfter: 10,
            },
            ads: {
                area: 'Ostatni',
                site: 'Recepty',
                type: 'GAM',
                variant: 'delay',
                mobileAfter: 10,
                desktop: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
                mobile: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
            },
            gemius: {
                typology: 'Recepty.cz',
                series: '',
                identifier: 'bac6P47Qpzf8KzcCXd4594ZRDovFRkOnIW4ZHK5pkvH.07',
            },
            googleAnalytics: {
                id: '',
                events: [],
            },
            appweb: {
                active: true,
                playerType: 'video',
            },
        },
        {
            magazineId: '26',
            magazineName: 'D\u00e1ma',
            custom: {
                autoplayVersion: 'always-desktop',
                mobileStopAfter: 10,
            },
            ads: {
                area: 'Ostatni',
                site: 'Dama',
                type: 'GAM',
                variant: 'delay',
                mobileAfter: 10,
                desktop: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
                mobile: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
            },
            gemius: {
                typology: 'D\u00e1ma',
                series: '',
                identifier: 'bac6P47Qpzf8KzcCXd4594ZRDovFRkOnIW4ZHK5pkvH.07',
            },
            googleAnalytics: {
                id: '',
                events: [],
            },
            appweb: {
                active: true,
                playerType: 'video',
            },
        },
        {
            magazineId: '25MM',
            magazineName: 'mobilmania.cz',
            custom: {
                autoplayVersion: 'always-desktop',
            },
            ads: {
                area: 'Ostatni',
                site: 'Mobilmania',
                type: 'GAM',
                variant: 'delay',
                desktop: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
                mobile: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
            },
            gemius: {
                typology: 'Mobilmania',
                series: '',
                identifier: 'bac6P47Qpzf8KzcCXd4594ZRDovFRkOnIW4ZHK5pkvH.07',
            },
            googleAnalytics: {
                id: 'UA-128524042-1',
                events: {
                    playVideo: {
                        status: true,
                        name: 'video play',
                    },
                    playAd: {
                        status: true,
                        name: 'ad video play',
                    },
                    load: {
                        status: true,
                        name: 'player load',
                    },
                    init: {
                        status: true,
                        name: 'player init',
                    },
                    ready: {
                        status: true,
                        name: 'player ready',
                    },
                    skipAd: {
                        status: true,
                        name: 'skip ad video',
                    },
                    endAd: {
                        status: true,
                        name: 'end ad video',
                    },
                    endVideo: {
                        status: true,
                        name: 'end video',
                    },
                },
            },
            appweb: {
                active: true,
                playerType: 'video',
            },
        },
        {
            magazineId: '25DP',
            magazineName: 'Doup\u011b',
            custom: {
                autoplayVersion: 'always-desktop',
            },
            ads: {
                area: 'Doupe',
                site: 'Zive',
                type: 'GAM',
                variant: 'delay',
                desktop: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
                mobile: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
            },
            gemius: {
                typology: '\u017div\u011b/Doup\u011b',
                series: '',
                identifier: 'bac6P47Qpzf8KzcCXd4594ZRDovFRkOnIW4ZHK5pkvH.07',
            },
            googleAnalytics: {
                id: 'UA-128524042-1',
                events: {
                    playVideo: {
                        status: true,
                        name: 'video play',
                    },
                    playAd: {
                        status: true,
                        name: 'ad video play',
                    },
                    load: {
                        status: true,
                        name: 'player load',
                    },
                    init: {
                        status: true,
                        name: 'player init',
                    },
                    ready: {
                        status: true,
                        name: 'player ready',
                    },
                    skipAd: {
                        status: true,
                        name: 'skip ad video',
                    },
                    endAd: {
                        status: true,
                        name: 'end ad video',
                    },
                    endVideo: {
                        status: true,
                        name: 'end video',
                    },
                },
            },
            appweb: {
                active: true,
                playerType: 'video',
            },
        },
        {
            magazineId: '25CO',
            magazineName: 'Connect.cz',
            custom: {
                autoplayVersion: 'always-desktop',
            },
            ads: {
                area: 'Connect',
                site: 'Zive',
                type: 'GAM',
                variant: 'delay',
                desktop: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
                mobile: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
            },
            gemius: {
                typology: '\u017div\u011b/Connect.cz',
                series: '',
                identifier: 'bac6P47Qpzf8KzcCXd4594ZRDovFRkOnIW4ZHK5pkvH.07',
            },
            googleAnalytics: {
                id: 'UA-128524042-1',
                events: {
                    playVideo: {
                        status: true,
                        name: 'video play',
                    },
                    playAd: {
                        status: true,
                        name: 'ad video play',
                    },
                    load: {
                        status: true,
                        name: 'player load',
                    },
                    init: {
                        status: true,
                        name: 'player init',
                    },
                    ready: {
                        status: true,
                        name: 'player ready',
                    },
                    skipAd: {
                        status: true,
                        name: 'skip ad video',
                    },
                    endAd: {
                        status: true,
                        name: 'end ad video',
                    },
                    endVideo: {
                        status: true,
                        name: 'end video',
                    },
                },
            },
            appweb: {
                active: true,
                playerType: 'video',
            },
        },
        {
            magazineId: '25',
            magazineName: '\u017div\u011b.cz',
            custom: {
                autoplayVersion: 'always-desktop',
            },
            ads: {
                area: 'Ostatni',
                site: 'Zive',
                type: 'GAM',
                variant: 'delay',
                desktop: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
                mobile: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
            },
            gemius: {
                typology: '\u017div\u011b',
                series: '',
                identifier: 'bac6P47Qpzf8KzcCXd4594ZRDovFRkOnIW4ZHK5pkvH.07',
            },
            googleAnalytics: {
                id: 'UA-128524042-1',
                events: {
                    playVideo: {
                        status: true,
                        name: 'video play',
                    },
                    playAd: {
                        status: true,
                        name: 'ad video play',
                    },
                    load: {
                        status: true,
                        name: 'player load',
                    },
                    init: {
                        status: true,
                        name: 'player init',
                    },
                    ready: {
                        status: true,
                        name: 'player ready',
                    },
                    skipAd: {
                        status: true,
                        name: 'skip ad video',
                    },
                    endAd: {
                        status: true,
                        name: 'end ad video',
                    },
                    endVideo: {
                        status: true,
                        name: 'end video',
                    },
                },
            },
            appweb: {
                active: true,
                playerType: 'video',
            },
        },
        {
            magazineId: '24',
            magazineName: 'Maminka',
            custom: {
                autoplayVersion: 'always-desktop',
                mobileStopAfter: 10,
            },
            ads: {
                area: 'Ostatni',
                site: 'Maminka',
                type: 'GAM',
                variant: 'delay',
                mobileAfter: 10,
                desktop: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
                mobile: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
            },
            gemius: {
                typology: 'Maminka',
                series: '',
                identifier: 'bac6P47Qpzf8KzcCXd4594ZRDovFRkOnIW4ZHK5pkvH.07',
            },
            googleAnalytics: {
                id: '',
                events: [],
            },
            appweb: {
                active: true,
                playerType: 'video',
            },
        },
        {
            magazineId: '21GS',
            magazineName: 'Moto GP Sport',
            custom: {
                autoplayVersion: 'always-desktop',
                mobileStopAfter: 10,
            },
            ads: {
                area: 'MotoGPSport',
                site: 'Autorevue',
                type: 'GAM',
                variant: 'delay',
                mobileAfter: 10,
                desktop: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
                mobile: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
            },
            gemius: {
                typology: 'Autorevue/MotoGPSport',
                series: '',
                identifier: 'bac6P47Qpzf8KzcCXd4594ZRDovFRkOnIW4ZHK5pkvH.07',
            },
            googleAnalytics: {
                id: '',
                events: [],
            },
            appweb: {
                active: true,
                playerType: 'video',
            },
        },
        {
            magazineId: '21F1',
            magazineName: 'F1 sport',
            custom: {
                autoplayVersion: 'always-desktop',
                mobileStopAfter: 10,
            },
            ads: {
                area: 'F1Sport',
                site: 'Auto',
                type: 'GAM',
                variant: 'delay',
                mobileAfter: 10,
                desktop: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
                mobile: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
            },
            gemius: {
                typology: 'Auto/F1Sport',
                series: '',
                identifier: 'bac6P47Qpzf8KzcCXd4594ZRDovFRkOnIW4ZHK5pkvH.07',
            },
            googleAnalytics: {
                id: '',
                events: [],
            },
            appweb: {
                active: true,
                playerType: 'video',
            },
        },
        {
            magazineId: '21AR',
            magazineName: 'Autorevue',
            custom: {
                autoplayVersion: 'always-desktop',
                mobileStopAfter: 10,
            },
            ads: {
                area: 'Ostatni',
                site: 'Autorevue',
                type: 'GAM',
                variant: 'delay',
                mobileAfter: 10,
                desktop: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
                mobile: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
            },
            gemius: {
                typology: 'Autorevue',
                series: '',
                identifier: 'bac6P47Qpzf8KzcCXd4594ZRDovFRkOnIW4ZHK5pkvH.07',
            },
            googleAnalytics: {
                id: '',
                events: [],
            },
            appweb: {
                active: true,
                playerType: 'video',
            },
        },
        {
            magazineId: '21',
            magazineName: 'mojezdravi.cz',
            custom: {
                autoplayVersion: 'always-desktop',
                mobileStopAfter: 10,
            },
            ads: {
                area: 'Ostatni',
                site: 'Mojezdravi',
                type: 'GAM',
                variant: 'delay',
                mobileAfter: 10,
                desktop: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
                mobile: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
            },
            gemius: {
                typology: 'Mojezdrav\u00ed',
                series: '',
                identifier: 'bac6P47Qpzf8KzcCXd4594ZRDovFRkOnIW4ZHK5pkvH.07',
            },
            googleAnalytics: {
                id: '',
                events: [],
            },
            appweb: {
                active: true,
                playerType: 'video',
            },
        },
        {
            magazineId: '20',
            magazineName: 'zeny.cz',
            custom: {
                autoplayVersion: 'always-desktop',
                mobileStopAfter: 10,
            },
            ads: {
                area: 'Ostatni',
                site: 'Zeny',
                type: 'GAM',
                variant: 'delay',
                mobileAfter: 10,
                // 'skipOffset': false,
                desktop: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
                mobile: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
            },
            gemius: {
                typology: '\u017deny.cz',
                series: '',
                identifier: 'bac6P47Qpzf8KzcCXd4594ZRDovFRkOnIW4ZHK5pkvH.07',
            },
            googleAnalytics: {
                id: '',
                events: [],
            },
            appweb: {
                active: true,
                playerType: 'video',
            },
        },
        {
            magazineId: '2',
            magazineName: 'iSport.cz',
            custom: {
                autoplayVersion: 'always-desktop',
                mobileStopAfter: 10,
            },
            ads: {
                area: 'TV_iSport',
                site: 'iSport',
                type: 'GAM',
                variant: 'delay',
                mobileAfter: 10,
                desktop: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
                mobile: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
            },
            gemius: {
                typology: 'Isport',
                series: '',
                identifier: 'bac6P47Qpzf8KzcCXd4594ZRDovFRkOnIW4ZHK5pkvH.07',
            },
            googleAnalytics: {
                id: '',
                events: [],
            },
            appweb: {
                active: true,
                playerType: 'video',
            },
        },
        {
            magazineId: '2ES',
            magazineName: 'grunex - esports',
            custom: {
                autoplayVersion: 'always-desktop',
                mobileStopAfter: 10,
            },
            ads: {
                area: 'Ostatni',
                site: 'GamingEsports',
                type: 'GAM',
                variant: 'delay',
                mobileAfter: 10,
                desktop: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
                mobile: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
            },
            gemius: {
                typology: 'Isport/grunex',
                series: '',
                identifier: 'bac6P47Qpzf8KzcCXd4594ZRDovFRkOnIW4ZHK5pkvH.07',
            },
            googleAnalytics: {
                id: '',
                events: [],
            },
            appweb: {
                active: true,
                playerType: 'video',
            },
        },
        {
            magazineId: '19',
            magazineName: 'recepty.blesk.cz',
            custom: {
                autoplayVersion: 'always-desktop',
                mobileStopAfter: 10,
            },
            ads: {
                area: 'Ostatni',
                site: 'Bleskprozeny',
                type: 'GAM',
                variant: 'delay',
                mobileAfter: 10,
                desktop: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
                mobile: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
            },
            gemius: {
                typology: 'Blesk',
                series: '',
                identifier: 'bac6P47Qpzf8KzcCXd4594ZRDovFRkOnIW4ZHK5pkvH.07',
            },
            googleAnalytics: {
                id: '',
                events: [],
            },
            appweb: {
                active: true,
                playerType: 'video',
            },
        },
        {
            magazineId: '18',
            magazineName: 'Aha!',
            custom: {
                autoplayVersion: 'always-desktop',
                mobileStopAfter: 10,
            },
            ads: {
                area: 'Ostatni',
                site: 'Ahaonline',
                type: 'GAM',
                variant: 'delay',
                mobileAfter: 10,
                desktop: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
                mobile: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
            },
            gemius: {
                typology: 'Ahaonline',
                series: '',
                identifier: 'bac6P47Qpzf8KzcCXd4594ZRDovFRkOnIW4ZHK5pkvH.07',
            },
            googleAnalytics: {
                id: '',
                events: [],
            },
            appweb: {
                active: true,
                playerType: 'video',
            },
        },
        {
            magazineId: '11DI',
            magazineName: 'Digi Arena',
            custom: {
                autoplayVersion: 'always-desktop',
            },
            ads: {
                area: 'Ostatni',
                site: 'DIGIarena',
                type: 'GAM',
                variant: 'delay',
                desktop: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
                mobile: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
            },
            gemius: {
                typology: '\u017div\u011b/DIGIarena',
                series: '',
                identifier: 'bac6P47Qpzf8KzcCXd4594ZRDovFRkOnIW4ZHK5pkvH.07',
            },
            googleAnalytics: {
                id: '',
                events: [],
            },
            appweb: {
                active: true,
                playerType: 'video',
            },
        },
        {
            magazineId: '11AV',
            magazineName: 'avmania.cz',
            custom: {
                autoplayVersion: 'always-desktop',
            },
            ads: {
                area: 'Ostatni',
                site: 'Avmania',
                type: 'GAM',
                variant: 'delay',
                desktop: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
                mobile: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
            },
            gemius: {
                typology: '\u017div\u011b/AVmania',
                series: '',
                identifier: 'bac6P47Qpzf8KzcCXd4594ZRDovFRkOnIW4ZHK5pkvH.07',
            },
            googleAnalytics: {
                id: '',
                events: [],
            },
            appweb: {
                active: true,
                playerType: 'video',
            },
        },
        {
            magazineId: '114',
            magazineName: 'supermamy.cz',
            custom: {
                autoplayVersion: 'always-desktop',
                mobileStopAfter: 10,
            },
            ads: {
                area: 'Ostatni',
                site: 'Supermamy',
                type: 'GAM',
                variant: 'delay',
                mobileAfter: 10,
                desktop: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
                mobile: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
            },
            gemius: {
                typology: 'Maminka/Supermamy',
                series: '',
                identifier: 'bac6P47Qpzf8KzcCXd4594ZRDovFRkOnIW4ZHK5pkvH.07',
            },
            googleAnalytics: {
                id: '',
                events: [],
            },
            appweb: {
                active: true,
                playerType: 'video',
            },
        },
        {
            magazineId: '11',
            magazineName: 'E15.cz',
            custom: {
                autoplayVersion: 'always-desktop',
                mobileStopAfter: 10,
            },
            ads: {
                area: 'Ostatni',
                site: 'E15',
                type: 'GAM',
                variant: 'delay',
                mobileAfter: 10,
                desktop: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
                mobile: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
            },
            gemius: {
                typology: 'E15',
                series: '',
                identifier: 'bac6P47Qpzf8KzcCXd4594ZRDovFRkOnIW4ZHK5pkvH.07',
            },
            googleAnalytics: {
                id: '',
                events: [],
            },
            appweb: {
                active: true,
                playerType: 'video',
            },
        },
        {
            magazineId: '103',
            magazineName: 'Pro \u017eeny',
            custom: {
                autoplayVersion: 'always-desktop',
                mobileStopAfter: 10,
            },
            ads: {
                area: 'Ostatni',
                site: 'Bleskprozeny',
                type: 'GAM',
                variant: 'delay',
                mobileAfter: 10,
                desktop: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
                mobile: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
            },
            gemius: {
                typology: 'Blesk/Blesk pro \u017eeny/TV Blesk pro \u017eeny',
                series: '',
                identifier: 'bac6P47Qpzf8KzcCXd4594ZRDovFRkOnIW4ZHK5pkvH.07',
            },
            googleAnalytics: {
                id: '',
                events: [],
            },
            appweb: {
                active: true,
                playerType: 'video',
            },
        },
        {
            magazineId: '102',
            magazineName: 'Pro \u017eeny',
            custom: {
                autoplayVersion: 'always-desktop',
                mobileStopAfter: 10,
            },
            ads: {
                area: 'Ostatni',
                site: 'Bleskprozeny',
                type: 'GAM',
                variant: 'delay',
                mobileAfter: 10,
                desktop: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
                mobile: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
            },
            gemius: {
                typology: 'Blesk/Blesk pro \u017eeny',
                series: '',
                identifier: 'bac6P47Qpzf8KzcCXd4594ZRDovFRkOnIW4ZHK5pkvH.07',
            },
            googleAnalytics: {
                id: '',
                events: [],
            },
            appweb: {
                active: true,
                playerType: 'video',
            },
        },
        {
            magazineId: '101',
            magazineName: 'Horoskopy',
            custom: {
                autoplayVersion: 'always-desktop',
                mobileStopAfter: 10,
            },
            ads: {
                area: 'Horoskopy',
                site: 'Bleskprozeny',
                type: 'GAM',
                variant: 'delay',
                mobileAfter: 10,
                desktop: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
                mobile: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
            },
            gemius: {
                typology: 'Blesk/Blesk pro \u017eeny/Horoskopy',
                series: '',
                identifier: 'bac6P47Qpzf8KzcCXd4594ZRDovFRkOnIW4ZHK5pkvH.07',
            },
            googleAnalytics: {
                id: '',
                events: [],
            },
            appweb: {
                active: true,
                playerType: 'video',
            },
        },
        {
            magazineId: '1',
            magazineName: 'Blesk.cz',
            custom: {
                autoplayVersion: 'always-desktop',
                mobileStopAfter: 10,
            },
            ads: {
                area: 'Ostatni',
                site: 'Blesk',
                type: 'GAM',
                variant: 'delay',
                mobileAfter: 10,
                desktop: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
                mobile: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
            },
            gemius: {
                typology: 'Blesk',
                series: '',
                identifier: 'bac6P47Qpzf8KzcCXd4594ZRDovFRkOnIW4ZHK5pkvH.07',
            },
            googleAnalytics: {
                id: '',
                events: [],
            },
            appweb: {
                active: true,
                playerType: 'video',
            },
        },
        {
            magazineId: '113',
            magazineName: 'Blesk.cz',
            custom: {
                autoplayVersion: 'always-desktop',
                mobileStopAfter: 10,
            },
            ads: {
                area: 'Ostatni',
                site: 'Blesk',
                type: 'GAM',
                variant: 'delay',
                mobileAfter: 10,
                desktop: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
                mobile: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
            },
            gemius: {
                typology: 'Blesk',
                series: '',
                identifier: 'bac6P47Qpzf8KzcCXd4594ZRDovFRkOnIW4ZHK5pkvH.07',
            },
            googleAnalytics: {
                id: '',
                events: [],
            },
            appweb: {
                active: true,
                playerType: 'video',
            },
        },
        {
            magazineId: '5',
            magazineName: 'Blesk.cz',
            custom: {
                autoplayVersion: 'always-desktop',
                mobileStopAfter: 10,
            },
            ads: {
                area: 'Ostatni',
                site: 'Abicko',
                type: 'GAM',
                variant: 'delay',
                mobileAfter: 10,
                desktop: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
                mobile: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
            },
            gemius: {
                typology: 'ABC',
                series: '',
                identifier: 'bac6P47Qpzf8KzcCXd4594ZRDovFRkOnIW4ZHK5pkvH.07',
            },
            googleAnalytics: {
                id: '',
                events: [],
            },
            appweb: {
                active: true,
                playerType: 'video',
            },
        },
        {
            magazineId: '2SR',
            magazineName: 'Sportrevue',
            custom: {
                autoplayVersion: 'always-desktop',
                mobileStopAfter: 10,
            },
            ads: {
                area: 'Ostatni',
                site: 'Sportrevue',
                type: 'GAM',
                variant: 'delay',
                mobileAfter: 10,
                desktop: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
                mobile: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
            },
            gemius: {
                typology: 'Isport/Sportrevue.cz',
                series: '',
                identifier: 'bac6P47Qpzf8KzcCXd4594ZRDovFRkOnIW4ZHK5pkvH.07',
            },
            googleAnalytics: {
                id: '',
                events: [],
            },
            appweb: {
                active: true,
                playerType: 'video',
            },
        },
        /* START settings for EOM  */
        {
            magazineId: '98',
            magazineName: 'extra',
            ads: {
                area: 'Ostatni',
                site: 'Extra',
                type: 'GAM',
                desktop: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: false,
                    midroll: false,
                },
                mobile: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: false,
                    midroll: false,
                },
            },
            gemius: {
                typology: 'extra.cz',
                series: '',
                identifier: 'AqTln2uEiak9y29GLVn7jJZz.K5dRuRV_Y04fCnr7wv.a7',
            },
            googleAnalytics: {
                id: '',
                events: [],
            },
        },
        {
            magazineId: '98ON',
            magazineName: 'onetv.cz',
            ads: {
                area: 'Ostatni',
                site: 'OneTV',
                type: 'GAM',
                desktop: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: false,
                    midroll: false,
                },
                mobile: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: false,
                    midroll: false,
                },
            },
            gemius: {
                typology: 'Onetv.cz',
                series: '',
                identifier: 'AqTln2uEiak9y29GLVn7jJZz.K5dRuRV_Y04fCnr7wv.a7',
            },
            googleAnalytics: {
                id: '',
                events: [],
            },
        },
        {
            magazineId: '98G',
            magazineName: 'g.cz',
            ads: {
                area: 'Ostatni',
                site: 'G',
                type: 'GAM',
                desktop: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: false,
                    midroll: false,
                },
                mobile: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: false,
                    midroll: false,
                },
            },
            gemius: {
                typology: 'g.cz',
                series: '',
                identifier: 'AqTln2uEiak9y29GLVn7jJZz.K5dRuRV_Y04fCnr7wv.a7',
            },
            googleAnalytics: {
                id: '',
                events: [],
            },
        },
        {
            magazineId: '98EL',
            magazineName: 'extralife.cz',
            ads: {
                area: 'Ostatni',
                site: 'extralife',
                type: 'GAM',
                desktop: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: false,
                    midroll: false,
                },
                mobile: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: false,
                    midroll: false,
                },
            },
            gemius: {
                typology: 'extralife.cz',
                series: '',
                identifier: 'AqTln2uEiak9y29GLVn7jJZz.K5dRuRV_Y04fCnr7wv.a7',
            },
            googleAnalytics: {
                id: '',
                events: [],
            },
        },
        {
            magazineId: '98ES',
            magazineName: 'extrastory.cz',
            ads: {
                area: 'Ostatni',
                site: 'extrastory',
                type: 'GAM',
                desktop: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: false,
                    midroll: false,
                },
                mobile: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: false,
                    midroll: false,
                },
            },
            gemius: {
                typology: 'extrastory.cz',
                series: '',
                identifier: 'AqTln2uEiak9y29GLVn7jJZz.K5dRuRV_Y04fCnr7wv.a7',
            },
            googleAnalytics: {
                id: '',
                events: [],
            },
        },
        {
            magazineId: '98L',
            magazineName: 'lifee.cz',
            ads: {
                area: 'Ostatni',
                site: 'lifee',
                type: 'GAM',
                desktop: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: false,
                    midroll: false,
                },
                mobile: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: false,
                    midroll: false,
                },
            },
            gemius: {
                typology: 'lifee.cz',
                series: '',
                identifier: 'AqTln2uEiak9y29GLVn7jJZz.K5dRuRV_Y04fCnr7wv.a7',
            },
            googleAnalytics: {
                id: '',
                events: [],
            },
        },
        /* END settings for EOM */

        /* settings for live stream  */
        {
            magazineId: '1-live',
            magazineName: 'Blesk.cz',
            custom: {
                autoplayVersion: 'always-desktop',
                mobileStopAfter: 10,
            },
            ads: {
                area: 'Ostatni',
                site: 'Blesk',
                type: 'GAM',
                variant: 'delay',
                mobileAfter: 10,
                desktop: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
                mobile: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
            },
            gemius: {
                typology: 'Blesk',
                series: 'Online Stream',
                identifier: 'bac6P47Qpzf8KzcCXd4594ZRDovFRkOnIW4ZHK5pkvH.07',
                prefix: 'onlinestream',
            },
            googleAnalytics: {
                id: '',
                events: [],
            },
            appweb: {
                active: true,
                playerType: 'video',
            },
        },
        {
            magazineId: '2-live',
            magazineName: 'iSport.cz',
            custom: {
                autoplayVersion: 'always-desktop',
                mobileStopAfter: 10,
            },
            ads: {
                area: 'TV_iSport',
                site: 'iSport',
                type: 'GAM',
                variant: 'delay',
                mobileAfter: 10,
                desktop: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
                mobile: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
            },
            gemius: {
                typology: 'Isport',
                series: 'Online Stream',
                identifier: 'bac6P47Qpzf8KzcCXd4594ZRDovFRkOnIW4ZHK5pkvH.07',
                prefix: 'onlinestream',
            },
            googleAnalytics: {
                id: '',
                events: [],
            },
            appweb: {
                active: true,
                playerType: 'video',
            },
        },
        {
            magazineId: '3301-live',
            magazineName: 'iSport TV',
            custom: {
                autoplayVersion: 'always-desktop',
                mobileStopAfter: 10,
            },
            ads: {
                area: 'TV_iSport',
                site: 'iSport',
                type: 'GAM',
                variant: 'delay',
                mobileAfter: 10,
                desktop: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
                mobile: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
            },
            gemius: {
                typology: 'Isport/TV Isport',
                series: 'Online Stream',
                identifier: 'bac6P47Qpzf8KzcCXd4594ZRDovFRkOnIW4ZHK5pkvH.07',
                prefix: 'onlinestream',
            },
            googleAnalytics: {
                id: '',
                events: [],
            },
            appweb: {
                active: true,
                playerType: 'video',
            },
        },
        {
            magazineId: '3303-live',
            magazineName: 'Blesk TV',
            custom: {
                autoplayVersion: 'always-desktop',
                mobileStopAfter: 10,
            },
            ads: {
                area: 'TV_BLESK',
                site: 'Blesk',
                type: 'GAM',
                variant: 'delay',
                mobileAfter: 10,
                desktop: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
                mobile: {
                    preroll1: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    preroll2: {
                        breakType: 'preroll',
                        breakTimingTyp: 'time',
                        'breakTimingValue ': 10,
                    },
                    postroll: {
                        breakType: 'postroll',
                    },
                    midroll: {
                        breakType: 'midroll',
                        breakTimingTyp: 'percentage',
                        'breakTimingValue ': 50,
                    },
                },
            },
            gemius: {
                typology: 'Blesk/TV Blesk',
                series: 'Online Stream',
                identifier: 'bac6P47Qpzf8KzcCXd4594ZRDovFRkOnIW4ZHK5pkvH.07',
                prefix: 'onlinestream',
            },
            googleAnalytics: {
                id: 'UA-128524042-1',
                events: {},
            },
            appweb: {
                active: true,
                playerType: 'video',
            },
        },
    ],
};

// export { cncVideoConfig };
