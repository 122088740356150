/**
 *
 * @author: Milan Machacek
 *
 * @create 2022-10-31
 *
 * @version 1.0
 * @description funkce pro získání základních informací o prohlížeči
 *
 */

/**
 * Jmeno browseru
 * @returns {RegExp|string|string}
 */
const browserName = () => {
    const { userAgent } = navigator;
    const browsers = [
        [/Chrome/, 'Chrome'],
        [/Firefox/, 'Firefox'],
        [/Safari/, 'Safari'],
        [/Edg/, 'Edge'],
        [/Opera/, 'Opera'],
    ];

    const result = browsers.find((entry) => entry[0].test(userAgent));

    return result[1] || 'other';
};

/**
 * Verze browseru
 * @returns {RegExpMatchArray|string|string}
 */
const browserVersion = () => {
    const versions = {
        default:
            /(?:Version|MSIE|Firefox|Chrome|QuickTime|rv:|BlackBerry[^/]+|CoreMedia v)[/ ]?([a-z0-9.]+)/i,
        Opera: /Opera\/.*? Version\/([\d.]+)/,
    };
    const { userAgent } = navigator;
    let id = browserName();
    let version;
    if (id !== 'opera') {
        id = 'default';
    }

    version = userAgent.match(versions[id]);
    if (version !== null) {
        version = RegExp.$1;
    }
    return version || '0.0';
};

/**
 * Platforma
 * @returns {RegExp|string|string}
 */
const browserPlatform = () => {
    const platforms = [
        [/Linux/, 'linux'],
        [/Windows/, 'windows'],
        [/Mac OS X/, 'mac'],
    ];

    const result = platforms.find((entry) => entry[0].test(navigator.userAgent));

    return result[1] || 'other';
};

/**
 * MObilni verze
 * @returns {boolean}
 */
const browserIsMobile = () => {
    const regexp = /(Mobi(le)?|Symbian|MIDP|Windows CE|BlackBerry|PSP|Opera Mini)/;
    return regexp.test(navigator.userAgent);
};

export { browserName, browserVersion, browserPlatform, browserIsMobile };
