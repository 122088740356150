import { Lang } from '../localization/cs-CZ';
import { CncVideoConfig } from '../config/cncVideoConfig';

/**
 * @description player utils - helper, useful functions....
 * @author Milan Machacek <milan1.machacek@cncenter.cz>, Alena Marečková, Jan Antoš
 * @version 1.0.2
 * @create 2022-10-26
 */

/**
 * @name utilsConsole
 *
 * @description If uncommented, writes mostly videoplayer events into dev tools console. It is used in almost every function using videoplayer events.
 * @param args
 * @returns {*[]}
 */
export const utilsConsole = (...args) => args;

/**
 *
 * @param length {int}
 * @returns {string} return random string
 */
export const randomString = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i += 1) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};

/**
 *
 * @param elementId {string} id elemen to check resolution
 * @returns {string} <width>x<height>
 */
export const elementResolution = (elementId) => {
    const el = document.getElementById(elementId);
    return `${el.offsetWidth}x${el.offsetHeight}`;
};

/**
 *
 * @param dateTime {string} datetime string
 * @returns {string} time
 */
export const utilTime = (dateTime) => {
    const _date = new Date(dateTime);
    let hour = _date.getHours();
    let minutes = _date.getMinutes();
    hour = hour < 10 ? `0${hour}` : hour;
    minutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${hour}:${minutes}`;
};

/**
 *
 * @param date {string} datetime string
 * @param withMonth {boolean} if true return name month
 * @param withTime {boolean} if true return date with time
 * @returns {string} time
 */
export const utilDate = (date, withMonth, withTime) => {
    const _months = Lang.months;
    const _date = new Date(date);
    const time = withTime === true ? `&nbsp;${utilTime(date)}` : '';
    const curMonth = withMonth === true ? _months[_date.getMonth()] : `${_date.getMonth() + 1}.`;
    return `${_date.getDate()}.&nbsp;${curMonth}&nbsp;${_date.getFullYear()}${time}`;
};

/**
 * sanitize kw
 * @param str {string} string to sanitize
 * @returns {string}
 */
export const sanitizeKw = (str) => {
    const strTrim = str.replace(/^\s+|\s+$/g, ''); // trim
    const strLower = strTrim.toLowerCase();

    const from =
        'ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:; ';
    const to =
        'AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa--_,,,,';
    let strClear = '';
    for (let i = 0, l = from.length; i < l; i += 1) {
        strClear = strLower.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    return strClear
        .replace(/[^a-z0-9_ -,]/g, '') // remove invalid chars
        .replace(/,-/g, ',')
        .replace(/,,/g, ',');
};

/**
 * Get all group fields from brand safety
 * @param flags {object} object of all video flags
 * @returns {string} group fields all brend flags
 */
export const parseBrandSafetyFlags = (flags) => {
    if (typeof flags !== 'object') {
        return '';
    }
    let item = {};
    let out = '';

    Object.keys(flags).forEach((value) => {
        item = flags[value];
        if (item.group_field === 'brand_safety') {
            out += `${item.defname},`;
        }
    });

    return out;
};

/**
 *
 * @param flags {object} object of all video flags
 * @param id {int} id of flag
 * @returns {object} flags with the find name or false
 */
export const getFlagsById = (flags, id) => {
    if (typeof flags !== 'object') {
        return false;
    }

    return flags.find((entry) => parseInt(entry.id, 10) === id);
};

/**
 *
 * @param flags {object} object of all video flags
 * @param defname {string} name of flag
 * @returns {object|boolean} flags with the find name or false
 */
export const getFlagsByDefName = (flags, defname) => {
    if (typeof flags !== 'object') {
        return false;
    }

    return Object.entries(flags).find((entry) => entry.defname === defname);
};

/**
 * get property of object
 * @param obj {object}
 * @param property {string}
 * @returns {string} value of property or ''
 */
export const getObjectProperty = (obj, property) => {
    if (typeof obj !== 'object') {
        return '';
    }

    if (property in obj) {
        return obj[property];
    }

    return '';
};

/**
 * @description - sjednoceni dvou objektu - do obj1 jsou pridany nenastavene vlastnosti z obj2,
 * pripadne nastavene prepasne hodnotou z obj2
 *
 * @param {object} obj1 - vychozi objekt
 * @param {object} obj2 - objekt, ktery upravuje obj1
 * @return {object} - vysledny objekt
 */
export const mergeObject = (obj1, obj2) => {
    const attributes = Object.keys(obj2);

    for (let i = 0; i < attributes.length; i += 1) {
        if (typeof obj1[attributes[i]] === 'object') {
            mergeObject(obj1[attributes[i]], obj2[attributes[i]]);
        } else {
            obj1[attributes[i]] = obj2[attributes[i]];
        }
    }

    return obj1;
};

/**
 *
 * @param action {string} (stop/pause/mute/unmute)
 * @param player {object} instance of vp player
 */
export const playerAction = (action, player) => {
    if (typeof action !== 'string') {
        // throw new Error(`playerUtils.playerAction: action isn't string but: ${typeof action}`);
    } else if (typeof player !== 'object') {
        // throw new Error(`playerUtils.playerAction: player isn't object but: ${typeof player}`);
    }

    switch (action) {
        // only video playing stop, advert playing stop not working
        case 'stop':
            player.stop();
            // closeRelated(player);
            break;
        case 'pause':
            player.pause();
            // closeRelated(player);
            break;
        case 'mute':
            player.mute();
            break;
        case 'unMute':
            player.unmute();
            break;
        case 'play':
            player.play();
            break;
        default:
    }
};

/**
 * control player on page without player with viewId
 * @param action {string} (stop/pause/mute/unmute)
 * @param viewId {string} viewId video player that will not be controlled
 */
export const allPlayerControl = (action, viewId) => {
    window.cncGjvpPlayers.map((entry) => {
        if (entry.configCNC.viewId !== viewId) {
            playerAction(action, entry.player);
        }
        return true;
    });
};

export const absolutelyAllPlayerControl = (action) => {
    window.cncGjvpPlayers.map((entry) => playerAction(action, entry.player));
};

export const galleryPlayerControl = (action, elementId, videoId) => {
    let item;
    Object.keys(window.cncGjvpPlayers).forEach((key) => {
        item = window.cncGjvpPlayers[key];

        if (
            item.configCNC.video.elementId.indexOf(elementId) > -1 &&
            item.configCNC.video.id !== videoId &&
            !item.player.isMuted()
        ) {
            playerAction(action, item.player);
        }
    });
};

/**
 * @description - detekce url pro galerie
 * @return {boolean|*} - true, pokud se jedna o detail galerie, jinak false
 */
export const isGalleryDetail = () => window.document.location.pathname.indexOf('/galerie/') !== -1;

/**
 * control player on page with video ID
 * @param videoId {int} video ID player that will be controlled
 * @param action {string} (stop/pause/mute/unmute)
 */
export const playerControl = (videoId, action) => {
    const result = window.cncGjvpPlayers.filter((entry) => entry.configCNC.video.id === videoId);

    if (result.length > 0) {
        playerAction(action, result[0].player);
    }
};

/**
 *
 * @param miniplayer {boolean} true if miniplayer
 * @param autoplay {boolean | string} true if autoplay video
 * @param next {boolean} true if related videos
 * @returns {string}
 */
export const gemiusTypePlayer = (miniplayer, autoplay, next) => {
    const adParam = (cond, value) => {
        if (cond) {
            return ` ${value}`;
        }
        return '';
    };

    let ret = '';

    if (miniplayer) {
        ret = 'Mini player';
    } else {
        ret = 'Normal';
    }

    ret += adParam(autoplay, 'autoplay');
    ret += adParam(next, 'related videos');

    return ret;
};

/**
 * get video ads config
 * @param realMag {int} real mag
 * @param videoCategory {string} original video category
 * @param videoKeywords {string} video keywords
 * @param mobile {boolean} true if mobile player
 * @returns {object} ads config object
 */
export const getVideoConfig = (realMag, videoCategory, videoKeywords, mobile) => {
    // pomocne funkce pro vypocty
    /**
     *
     * @param obj {object}
     * @param key {string} key to setting
     * @param config {object}
     * @param path {string}
     */
    const setNewVal = (obj, key, config, path) => {
        let result = '';

        // get value from input
        if (key in obj) {
            result = obj[key];
        }
        // set value to output
        if (result !== '') {
            config[path][key] = result;
        }
    };

    /**
     *
     * @param config {object}
     * @param key {string}
     * @param value {string}
     * @param useKeyword {boolean}
     * @returns {object}
     */
    const configFindBy = (config, key, value, useKeyword) => {
        let item = false;
        let result = false;

        Object.keys(config).forEach((_key) => {
            item = config[_key];

            if (useKeyword) {
                if (value.indexOf(item[key]) > -1) {
                    result = item;
                }
            } else if (item[key] === value) {
                result = item;
            }
        });
        return JSON.parse(JSON.stringify(result));
    };

    /**
     * find config for sub magazine
     * @param magId {string} sub magazine id
     * @returns {string} magazine id
     */
    const getRealMag = (magId) => {
        const magazine = CncVideoConfig.magazine.filter((entry) => entry.ids.includes(magId));

        return magazine.length > 0 ? magazine[0].realId : magId;
    };

    // obsah funkce
    const realMagLoc = getRealMag(realMag.toString());
    const out = configFindBy(CncVideoConfig.config, 'magazineId', realMagLoc, false);
    const cat = configFindBy(CncVideoConfig.categories, 'id', parseInt(videoCategory, 10), false);
    const kw = configFindBy(CncVideoConfig.keywords, 'keyword', videoKeywords, true);

    if (!out) {
        return false;
    }

    if (kw) {
        setNewVal(kw, 'series', out, 'gemius');
        setNewVal(kw, 'site', out, 'ads');
        setNewVal(kw, 'area', out, 'ads');
    } else if (cat) {
        setNewVal(cat, 'series', out, 'gemius');
        setNewVal(cat, 'site', out, 'ads');
        setNewVal(cat, 'area', out, 'ads');
    }
    // setting ads
    if (mobile) {
        out.ads.preroll1 = out.ads.mobile.preroll1 || false;
        out.ads.preroll2 = out.ads.mobile.preroll2 || false;
        out.ads.postroll = out.ads.mobile.postroll || false;
        out.ads.overlayer = out.ads.mobile.overlayer || false;
        out.ads.midroll = out.ads.mobile.midroll || false;
        out.ads.site += '_Mobile';
    } else {
        out.ads.preroll1 = out.ads.desktop.preroll1 || false;
        out.ads.preroll2 = out.ads.desktop.preroll2 || false;
        out.ads.postroll = out.ads.desktop.postroll || false;
        out.ads.overlayer = out.ads.desktop.overlayer || false;
        out.ads.midroll = out.ads.desktop.midroll || false;
    }

    delete out.ads.desktop;
    delete out.ads.mobile;

    return out;
};

/**
 * @param {string} magId - id magazinu
 */
export const getMagazineConfigById = (magId) => {
    // jinak najdeme v configu
    const result = CncVideoConfig.config.filter((entry) => entry.magazineId === magId);

    return result[0] || false;
};

/**
 * @description - nalezeni jmena magazinu v configu pro zadane id
 * @param {string} magId - id magazinu
 * @return {string} - jmeno magazinu
 */
export const getMagazineNameFromId = (magId) => {
    const result = getMagazineConfigById(magId);

    return result ? result.magazineName : '';
};

/**
 * @description - nalezeni typology magazinu v configu pro zadane id
 * @param {string} magId - id magazinu
 * @return {string} - jmeno magazinu
 */
export const getMagazineTypologyFromId = (magId) => {
    const result = getMagazineConfigById(magId);

    return result ? result.gemius.typology : '';
};

/**
 * dispatch custom video player events
 * @param playerId {string} video element id
 * @param name {string} events name
 * @param data {object} send data
 */
export const sendEvent = (playerId, name, data) => {
    const _data = data || {};
    const _name = `on${name.charAt(0).toUpperCase()}${name.slice(1)}`;
    const event = new CustomEvent(name, {
        detail: {
            _data,
            time: new Date(),
        },
        bubbles: true,
        cancelable: true,
    });
    document.getElementById(playerId).dispatchEvent(event);

    if (_name === 'onComplete') {
        window.top.postMessage(
            {
                name: 'autoplayNext',
            },
            '*',
        );
    }
};

/**
 * @description funkce pro extrakci hodnoty get parametru z url
 * @author Ivan Mihalic
 * @param {string} keyName -  nazev parametru, jehoz hodnotu chceme ziskat
 * @return {{}|undefined|*} - hodnota parametru nebo undefined, pri vyjimce prazdny objekt
 */

export const getUrlQueryParams = (keyName) => {
    const query = window.location.search.substring(1);
    const vars = query.split('&');
    const queryParams = {};

    try {
        for (let i = 0; i < vars.length; i += 1) {
            if (typeof vars[i] === 'string' && !vars[i] === '' && vars[i].match('=')) {
                const pair = vars[i].split('=');
                queryParams[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
            }
        }

        if (typeof keyName === 'string' && keyName !== '') {
            if (!queryParams[keyName]) {
                return undefined;
            }
            return queryParams[keyName];
        }

        return queryParams;
    } catch (e) {
        /* eslint-disable no-console */
        console.error(e);
        /* eslint-enable no-console */
        return {};
    }
};

/**
 * @description - funkce pro urceni template pro adrequest googima
 * @param { boolean } miniplayer - je prehravac miniplayer?
 * @return { string } - nazev template
 */
export const getGAMTemplate = (miniplayer) => {
    if (miniplayer) {
        return 'smallplayer';
    }
    return 'bigplayer_play';
};

/**
 * @description - funkce vrací počet midrolů pro danné video podle jeho délky
 * @param {number} duration - délka videa v sekundách
 * @param {number} interval - minimální vzdálenost mezi  midrolly
 * @returns {number} - počet midrolů pro danné video
 */
export const getMidrollCount = (duration, interval) => Math.floor(duration / 60 / interval) - 1;

/**
 * @description - funkce pro urceni, jestli jde o web Webas (Brno)
 * @param debugParams {array}
 * @param target {string} desktop | mobile default desktop
 * @returns {boolean}
 */
export const isWebas = (debugParams, target) => {
    const _debugParams = debugParams || [];

    if (_debugParams.indexOf('mwebas') >= 0 || _debugParams.indexOf('dwebas') >= 0) {
        return true;
    }
    const _target = target || 'desktop';
    const url = window.location.host;
    const webasDomains = {
        desktop: [
            'zive.cz',
            'vtm.zive.cz',
            'connect.zive.cz',
            'doupe.zive.cz',
            'mobilmania.zive.cz',
            'avmania.zive.cz',
            'digiarena.zive.cz',
        ],
        mobile: [
            'm.zive.cz',
            'm.vtm.zive.cz',
            'm.connect.zive.cz',
            'm.doupe.zive.cz',
            'm.mobilmania.zive.cz',
            'm.avmania.zive.cz',
            'm.digiarena.zive.cz',
        ],
    };

    return url in webasDomains[_target];
};

/**
 * @description - detekce listovaciho clanku
 * @return {boolean|*} - true, pokud se jedna o listovaci clanek, jinak false
 */
export const isArticleListed = () => {
    if (document.querySelector('ul.listed') || document.querySelector('article.article-listed')) {
        return true;
    }

    // brnenske listovaci clanky
    return (
        document.querySelectorAll('.bodyPart') && document.querySelectorAll('.bodyPart').length > 0
    );
};

/**
 * @description - najde tag ohranicujici kapitolu, ve ktere je video - CNC
 * @param {HTMLElement} el - html element, pro ktery se hleda nejblizsi nadrazeny s elName
 * @param {string} elName - tag, napr. LI
 * @return {HTMLElement} - html element ohranicujici aktivni kapitolu clanku
 */
export const getArticlePartCNC = (el, elName) => {
    let result = el;

    while (result.nodeName !== elName) {
        result = result.parentElement;
    }

    return result;
};

/**
 * @description - testuje, jestli se jedná a viditelnou kapitolu listovacího článku - CNC
 * @param {HTMLElement} el - html element ohranicujici aktivni kapitolu clanku
 * @param { string } cls - css class podle ktereho se detekuje viditelna kapitola listovaciho clanku
 * @return {boolean} - true = jde o viditelnou kapitolu, jinak false
 */
export const isActiveArticlePartCNC = (el, cls) => el.classList.contains(cls);

/**
 * @description - najde tag ohranicujici kapitolu, ve ktere je video - webas
 * @param {HTMLElement} el - element
 * @param {string} cls - css class, podle ktereho se detekuje kapitola clanku
 */
export const getArticlePartWebas = (el, cls) => {
    let result = el;

    while (!result.classList.contains(cls)) {
        result = result.parentElement;
    }

    return result;
};

/**
 * @description - testuje, jestli se jedná a viditelnou kapitolu listovacího článku - Webas
 * @param {HTMLElement} el - html element ohranicujici aktivni kapitolu clanku
 */
export const isActiveArticlePartWebas = (el) => el.style.display && el.style.display === 'block';

/**
 * @description - vraci hodnotu zadaneho klice v cookie nebo null
 * @author Ivan Mihalic
 * @param {string} keyName
 * @return {string|null} - hodnota zadaneho klice v cookie, pokud je,  nebo null pokud neni nebo pri vyjimce
 */
export const getCookie = (keyName) => {
    try {
        const name = `${keyName}=`;
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');

        for (let i = 0; i < ca.length; i += 1) {
            let c = ca[i];

            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }

            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }

        return null;
    } catch (e) {
        /* eslint-disable no-console */
        console.error(e);
        /* eslint-enable no-console */
        return null;
    }
};

/**
 * @description -detekce Seznam Trafficu
 * @author Ivan Mihalic
 * @return {boolean} - TRUE pokud ma jit Seznam Traffic -> tzn. nema se poustet CNC reklama
 */
export const seznamTrafficIsON = () => {
    try {
        const seznamCookie = getCookie('cnc_seznam_cookie');
        const queryParams = getUrlQueryParams('');

        const isUtmMatched =
            'utm_source' in queryParams &&
            'utm_medium' in queryParams &&
            ['seznam.cz', 'www.seznam.cz'].indexOf(queryParams.utm_source) > -1 &&
            ['sekce-z-internetu', 'denni-tisk', 'z-boxiku', 'sbrowser'].indexOf(
                queryParams.utm_medium,
            ) > -1;

        // pokud je platna cookie nebo UTM
        return !!(isUtmMatched || seznamCookie);
    } catch (e) {
        /* eslint-disable no-console */
        console.error(e);
        /* eslint-enable no-console */
        return false;
    }
};

/**
 * @description detekce videa, které nemá obsahovat reklamu
 * @param {object} options - parametry, podle kterých se určuje, zda se má přehrávat reklama
 * @property {string} options.kw - klicova slova (keywords) pro video
 * @property {string} options.adServerPath - tagUrl - zakladni url pro request na reklamu
 * @property {object} options.flags - flagy pro video
 * @return {boolean} - true, pokud se ve videu nemaji prehravat reklamy
 */
export const videoWithoutAds = (options) => {
    const kw = options.kw || '';
    const flags = options.flags || '';
    const adUrl = options.adUrl || '';
    const seznamTraffic = seznamTrafficIsON();

    return (
        !!getFlagsByDefName(flags, 'no-ad') ||
        seznamTraffic ||
        adUrl === '' ||
        kw.indexOf('no-ad') > -1 ||
        kw.indexOf('pr-video') > -1
    );
};

/**
 * @description detekce videa, ktere nema mit souvisejici
 * @param  {object } options -  - parametry, podle kterých se určuje, zda ma video mit souvisejici videa
 * @property {string} options.kw - klicova slova (keywords) pro video
 * @property {string} options.relatedVideosUrl- zakladni url pro souvisejici videa
 * @property {object} options.flags - flagy pro video
 * @return {boolean}
 */
export const videoWithoutRelated = (options) => {
    const kw = options.kw || '';
    const flags = options.flags || '';
    const relateVideosUrl = options.relatedVideosUrl || '';

    return (
        !!getFlagsByDefName(flags, 'pr-video') ||
        relateVideosUrl === '' ||
        kw.indexOf('pr-video') > -1
    );
};

/**
 * @description nastaveni, zda se ma video spustit pri nacteni stranky
 * @param { string } autostartOnLoad - p. e. 'true,true (state. onMobile)
 * @return {object} autostartOnLoadObj - {{onData: boolean, state: boolean, onMobile: boolean}}
 */
export const getAutostartOnLoad = (autostartOnLoad) => {
    let autostartOnLoadObj;
    if (typeof autostartOnLoad === 'string' && autostartOnLoad.length > 0) {
        const autostartOnLoadArr = autostartOnLoad.split(',');
        autostartOnLoadObj = {
            state: autostartOnLoadArr[0] === 'true',
            onMobile: autostartOnLoadArr[1] === 'true',
            onData: autostartOnLoadArr[2] === 'true',
        };
    } else {
        autostartOnLoadObj = {
            state: false,
            onMobile: false,
            onData: false,
        };
    }
    return autostartOnLoadObj;
};

/**
 * @description - nastaveni, zda se ma video spustit, kdyz je ve viewportu
 * @param { string } autostartOnViewable - p.e. 'true, true,0.5 (state, onMobile, percentageViewable 0 - 1)
 * @return { object } autostartOnViewableObj - {{onData: boolean, percentageViewable: number, state: boolean, onMobile: boolean}}
 */
export const getAutoStartOnViewable = (autostartOnViewable) => {
    const autostartOnViewableObj = {
        state: true,
        onMobile: true,
        onData: true,
        percentageViewable: 0.5,
    };

    if (typeof autostartOnViewable === 'string' && autostartOnViewable.length > 0) {
        const autostartOnViewableArr = autostartOnViewable.split(',');
        autostartOnViewableObj.state = autostartOnViewableArr[0] === 'true';
        autostartOnViewableObj.onMobile = autostartOnViewableArr[1] === 'true';
        autostartOnViewableObj.onData = autostartOnViewableArr[2] === 'true';
        autostartOnViewableObj.percentageViewable = autostartOnViewableArr[3] || 0.5;
    }

    return autostartOnViewableObj;
};

/**
 * nastaveni, zda se ma video mimo viewport zastavit
 * @param { string } autopauseOnViewable - p.e. 'true, 0.5' (state. percentageViewable 0 - 1)
 * @return { object } autopauseOnViewableObj -  {{percentageViewable: number, state: boolean}|{onData: boolean, percentageViewable: number}}
 */
export const getAutoPauseOnViewable = (autopauseOnViewable) => {
    const autoPauseOnViewableObj = {
        state: false,
        percentageViewable: 0.3,
    };

    if (typeof autopauseOnViewable === 'string' && autopauseOnViewable.length > 0) {
        const autoPauseOnViewableArr = autopauseOnViewable.split(',');
        autoPauseOnViewableObj.state = autoPauseOnViewableArr[0] === 'true';
        autoPauseOnViewableObj.percentageViewable = autoPauseOnViewableArr[1] || 0.3;
    }

    return autoPauseOnViewableObj;
};

/**
 * nastaveni floatingu
 * @param { string } float - p.e. 'true,true, true, bottomRight,false' (state, onMobile, dissmissible, position, requiresInteraction)
 * @return { object} floatObj - {{dismissible: boolean,  state: boolean, position: string, requiresInteraction: boolean, onMobile: boolean}|{dismissible: *, state: boolean, position, onMobile: boolean}}
 */
export const getFloat = (float) => {
    let floatObj;

    if (typeof float === 'string' && float.length > 0) {
        const floatArr = float.split(',');
        floatObj = {
            state: floatArr[0] === 'true',
            onMobile: floatArr[1] === 'true',
            dismissible: floatArr[2] === 'true',
            requiresInteraction: false,
        };
    } else {
        floatObj = {
            state: false,
            onMobile: false,
            dismissible: true,
            requiresInteraction: false,
        };
    }

    if (isArticleListed()) {
        floatObj = {
            state: false,
            onMobile: false,
            dismissible: true,
            requiresInteraction: false,
        };
    }
    return floatObj;
};

/**
 * @description - nastaveni ovladacich prvku, ktere s ebudou zobrazovat
 * @param { string } controls - p.e. 'nextButton, fullScreenButton, theaterButton, settingsButton'
 * @return { object } controlsObj - object s ovladacimi prvky
 */
export const getControls = (controls) => {
    let controlsArr;
    const controlsObj = {};
    if (typeof controls === 'string' && controls.length > 0) {
        controlsArr = controls.split(',');
        for (let i = 0; i < controlsArr.length; i += 1) {
            controlsObj[controlsArr[i].trim()] = true;
        }
    }
    return controlsObj;
};

export const isTopVideoOnPage = () => {
    const result = window.cncGjvpPlayers.filter((entry) => entry.configCNC.video.top === true);

    return result.length > 0;
};

export const isAutoplayVideoOnPage = () => {
    const result = window.cncGjvpPlayers.filter(
        (entry) =>
            entry.configVP.config.autostartOnViewable.state === true ||
            entry.configVP.config.autostartOnLoad.state === true,
    );

    return result.length > 0;
};
