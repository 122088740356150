import { elementResolution, randomString } from '../utils/playerUtils';
import { browserName, browserVersion } from '../utils/CheckBrowser';

/**
 *
 * @author Milan Machacek <machacek76@gmail.com>
 *
 * @create 2019-07-19
 * @update 2023-05-05
 * @version 0.8.0
 * @description function for send gemius prism a send video and ads hit
 *
 */

/**
 *
 * @param {object} config
 */

export function GemiusService(config) {
    const self = this;
    let player;

    const settings = {
        duration: config.video.durationSeconds || 0,
        title: config.video.title || 'not setting',
        prefix: config.gemius.prefix || '',
        ga: config.gemius.ga || '',
        typology: config.gemius.typology || '',
        series: config.gemius.series || '',
        playertype: config.gemius.playertype || '',
        quality: config.quality || '420p',
        source: config.video.source || 'Czech News Center',

        playerId: `player_${randomString(24)}`,
        videoId: config.video.id || '-1',
        accountId: config.gemius.identifier || '',
        resolution: elementResolution(config.video.elementId),
        currentDomain: window.location.hostname,
        videoMagazine: config.gemius.videoMagazine,

        browserInfo: `${browserName()} - ${browserVersion()}`,
    };

    /**
     * create video object for send to gemius prism
     * @returns [object]
     */
    self.setVideoObject = () => {
        const out = {
            programType: 'Video',
            programDuration: settings.duration,
            programName: `${settings.prefix} ${settings.title.replace(/(['"„“†`])/g, '')}`,
            quality: self.convertQuality(settings.quality),
            typology: settings.typology,
        };

        const add = (name, param) => {
            if (param !== undefined && param !== '' && param !== null) {
                out[name] = param;
            }
        };

        // pridani klicu ktere muzou byt prazdne
        add('programProducer', settings.source.replace(/(['"„“†`])/g, ''));
        add('typology', settings.typology);
        add('series', settings.series);
        add('customAttribute1', settings.playertype);
        add('customAttribute2', settings.videoMagazine);
        add('customAttribute3', settings.browserInfo);
        add('customAttribute4', 'VP player');

        return out;
    };

    self.convertQuality = (quality) => {
        switch (quality) {
            case '180p':
                return '320x180';
            case '240p':
                return '320x240';
            case '270p':
                return '480x270';
            case '360p':
                return '640x360';
            case '406p':
                return '720x406';
            case '420p':
                return '746x420';
            case '720p':
                return '1280x720';
            case '1080p':
                return '1920x1080';
            default:
                return '1280x720';
        }
    };

    /**
     * sending hit
     */
    self.sendHit = (time, type) =>
        new Promise((resolve, reject) => {
            try {
                player.programEvent(settings.videoId, time, type);
                resolve(true);
            } catch (e) {
                reject(e);
            }
        });

    /**
     * sending ad hit
     */
    self.sendAdHit = (time, type, adId) =>
        new Promise((resolve, reject) => {
            try {
                player.adEvent(settings.videoId, adId, time, type);
            } catch (e) {
                reject(e);
            }
        });

    self.setAdVideoObject = (duration, title, adId, position) => {
        player.newAd(adId, { duration, title, adType: position });
    };

    // init gemius player
    try {
        player = new window.GemiusPlayer(settings.playerId, settings.accountId, {
            resolution: settings.resolution,
            currentDomain: settings.currentDomain,
        });
        player.newProgram(settings.videoId, self.setVideoObject());
    } catch (e) {
        /* eslint-disable no-console */
        console.error(e);
        /* eslint-enable no-console */
    }

    return self;
}
