import { CncVpDash } from './VpDashPlayer';
import VideoAdRequest from './ads/videoAdRequest';
import { CncVpStreamPlayer } from './VpStreamPlayer';

const videoAdRequest = VideoAdRequest();
window.getPreRollUrl = videoAdRequest.getPreRollUrl.bind(videoAdRequest.getPreRollUrl);
window.getMidRollUrl = videoAdRequest.getMidRollUrl.bind(videoAdRequest.getMidRollUrl);
window.getPostRollUrl = videoAdRequest.getPostRollUrl.bind(videoAdRequest.getPostRollUrl);
window.CNC_vpDash = CncVpDash;
window.CNC_vpStreamPlayer = CncVpStreamPlayer;
