import { Lang } from './localization/cs-CZ';
import { GemiusService } from './analytics/GemiusService';
import { AppWebService } from './analytics/AppWebService';
import { SetVpObj } from './core/SetVpObj';
import { allPlayerControl, getVideoConfig, randomString, sanitizeKw } from './utils/playerUtils';
import { browserIsMobile } from './utils/CheckBrowser';

/**
 * @description VP stream player
 * @author Milan Machacek <milan1.machacek@cncenter.cz>
 * @version 1.0.0
 * @create 2019-08-01
 *
 */

/* eslint-disable camelcase */

export function CncVpStreamPlayer(config) {
    const self = this;
    /* eslint-disable no-unused-vars */
    self.version = '1.1.0-stream';
    /* eslint-enable no-unused-vars */
    self.settings = {};
    self.configCNC = {};
    self.isMobile = browserIsMobile();

    self.settings = {
        video: {
            thumbnail: config.video.poster || '',
            live: true,
            liveType: 'realtime',
        },
        config: {
            logo: {
                state: true,
                file: config.logo || '',
                position: 'TOP_LEFT',
                style: {
                    width: 'auto',
                },
            },
            lang: Lang().cz,
        },
        videoData: config.video,
        adServerPath: config.adServerPath,
        viewId: randomString(12),
        category: {
            magazine: config.realMag,
        },
        appWeb: config.appWeb,
    };

    self.settings.videoData.category = {
        magazine: config.realMag,
        magazineName: 'onlinestream',
    };

    // get config for online stream
    let cfg = getVideoConfig(`${config.realMag}-live`, 0, '', self.isMobile);
    // if not config for online stream, get config for stream
    if (!cfg) {
        cfg = getVideoConfig(config.realMag, 0, '', self.isMobile);
        cfg.gemius.series = 'Online Stream';
        cfg.gemius.prefix = 'onlinestream';
    }
    // set gemius custom attribute 1
    cfg.gemius.typePlayer = 'Online Stream';

    self.settings.gemius = cfg.gemius;
    self.settings.ads = cfg.ads;
    self.settings.video.site = cfg.ads.site;
    self.settings.video.area = cfg.ads.area;

    // for GA4
    self.settings.videoData.category.magazineName = cfg.magazineName;

    // url for m3u8 source from livebox script
    if (config.archive) {
        self.src = window.LiveboxArchSource.link_for(config.cn, 'hls');
    } else if (typeof window.LiveboxLinkSource === 'undefined') {
        self.src = window.LiveboxLiveSource.link_for(config.cn, 'hls');
    } else {
        self.src = window.LiveboxLinkSource.link_for(config.cn, 'hls');
    }

    self.cnt = 0;
    self.time = 0;
    self.SetVpObj = new SetVpObj();

    // init player on element
    self.player = window.vpPlayer(self.settings.videoData.elementId);

    /**
     * AppWebservice odesilame na jednom miste, abychom nemuseli pred kazdym odeslanim kontrolovat zdali mame
     * @param serviceFunction
     * @param settingObject
     * @returns {null|boolean}
     */
    self.sendAppService = (serviceFunction, settingObject) =>
        new Promise((resolve) => {
            if (self.appWebService && serviceFunction in self.appWebService) {
                self.appWebService[serviceFunction](settingObject);
                resolve(true);
            }
            resolve(false);
        });
    // settings AppWeb, if is settings in bin/cncVideoConfig.js
    if (self.settings.appWeb.contentType === 'live' && cfg.appweb && cfg.appweb.active) {
        self.settings.appWeb.show = cfg.gemius.series;
        self.settings.appWeb.playerType = self.settings.videoData.miniplayer
            ? 'videoMini'
            : 'videoNormal';
        self.appWebService = new AppWebService({
            video: self.settings.videoData,
            appWeb: self.settings.appWeb,
        });
    }

    self.construct = () => {
        if (typeof self.src === 'undefined') {
            self.time += 500;
            self.cnt += 1;
            if (self.cnt < 5) {
                setInterval(() => {
                    self.construct();
                }, self.time);
            }
        } else {
            const cnf = self.SetVpObj;
            cnf.video = self.settings.video;
            self.configCNC.viewId = self.settings.viewId;

            // livebox hack redirect m3u8
            const xhr = new XMLHttpRequest();
            xhr.open('GET', self.src);
            xhr.send();
            xhr.onload = () => {
                cnf.video.file = xhr.responseURL;
                // remova advertising if in kw is no-ad
                if (self.settings.videoData.keywords.indexOf('no-ad') > -1) {
                    cnf.advertising = {};
                } else {
                    // set advert
                    Object.keys(cnf.advertising.schedule).forEach((key) => {
                        cnf.advertising.schedule[key].tag = self.setAdUrl(
                            cnf.advertising.schedule[key].tag,
                        );
                    });
                }

                self.player.setup(self.settings);
                // self.player.on('vpEvent', self.VPAPandMeasurement);
                self.player.play();
            };
        }
    };

    // set variable in advert url
    self.setAdUrl = (path) => {
        const ret = path
            .replace('__item-viewid__', randomString(12))
            .replace('__item-template__', 'stream')
            .replace('__item-area__', self.settings.videoData.area)
            .replace('__item-site__', self.settings.videoData.site)
            .replace('__item-keywords__', '');

        return ret + sanitizeKw(self.settings.videoData.keywords);
    };

    // gemius maping
    self.mapGemius = (obj) => {
        Object.keys(obj).forEach((key) => {
            obj[key] = typeof obj[key] !== 'string' ? '' : obj[key];
        });

        obj.prefix = self.settings.gemius.prefix;

        return obj;
    };

    /**
     * @description - obsluha eventu - VPAP a měření
     * @param {event} evt
     *
     */
    self.VPAPandMeasurement = (evt) => {
        let eventName;
        if (evt) {
            eventName = evt.eventName ? evt.eventName : 'none';
        }

        // TODO overit jestli funguje neznama promenna
        const { info } = window;
        const actualQuality = info.levels[info.currentQuality];
        const resolution =
            actualQuality.label.toLowerCase() === 'auto'
                ? 'auto'
                : `${actualQuality.width}x${actualQuality.height}`;

        switch (eventName) {
            // video player is initialized and ready
            case 'ready':
                // setting Gemius prism
                self.gemiusService = new GemiusService({
                    gemius: self.settings.gemius,
                    video: self.settings.videoData,
                });
                if (self.appWebService) {
                    self.appWebService.sentPlayerData(
                        'player_start',
                        self.player.getPosition(),
                        true,
                    );
                    self.playTime = 0;
                }
                break;

            // video play
            case 'play':
                self.gemiusService.sendHit(self.player.getPosition(), 'play');
                allPlayerControl('pause', self.settings.viewId);
                if (self.player.getPosition() > 0) {
                    self.sendAppService('sentPlayerEventWithTime', {
                        eventType: 'player_resume',
                        timePosition: self.player.getPosition(),
                    });
                }
                break;

            // start playing
            case 'firstFrame':
                self.sendAppService('sentPlayerEventWithTime', {
                    eventType: 'player_play',
                    timePosition: self.player.getPosition(),
                });
                self.playTime = Math.round(self.player.getPosition());
                break;

            // on playing
            case 'time':
                self.playerProgress({
                    actualTime: self.player.getPosition(),
                    oldTime: self.playTime,
                });
                break;

            case 'playlistItem':
                break;

            // video pause
            case 'pause':
                self.sendAppService('sentPlayerEventWithTime', {
                    eventType: 'player_pause',
                    timePosition: self.player.getPosition(),
                });
                self.gemiusService.sendHit(self.player.getPosition(), 'pause');
                break;

            // seek
            case 'seek':
                self.sendAppService('sentPlayerEventWithTime', {
                    eventType: 'player_seeking',
                    timePosition: evt.currentTime,
                });
                break;

            // seeked
            case 'seeked':
                self.gemiusService.sendHit(self.player.getPosition(), 'seek');
                self.sendAppService('sentPlayerEventWithTime', {
                    eventType: 'player_seeking',
                    timePosition: self.player.getPosition(),
                });
                self.playTime = Math.round(self.player.getPosition());
                break;

            // buffer change
            case 'bufferChange':
                self.gemiusService.sendHit(self.player.getPosition(), 'buffer');
                break;

            // buffer full
            case 'bufferFull':
                if (evt.currentTime > 0) {
                    self.sendAppService('sentPlayerEventWithTime', {
                        eventType: 'player_buffering',
                        timePosition: self.player.getPosition(),
                    });
                }
                break;

            // changing volume (info = {volume: 52.840, type: "volume"})
            case 'volume':
                self.gemiusService.sendHit(self.player.getPosition(), 'chngVol');
                break;

            // mute
            case 'mute':
                self.sendAppService('sentPlayerSetting', {
                    eventType: 'player_settings_change_mute',
                    item: 'mute',
                    itemValue: info.mute,
                    timePosition: info.currentTime,
                });
                break;

            // change quality
            case 'levelsChanged':
                self.gemiusService.sendHit(self.player.getPosition(), 'chngQual');

                self.sendAppService('sentPlayerSetting', {
                    eventType: 'player_settings_change_resolution',
                    item: 'resolution',
                    itemValue: resolution,
                    timePosition: self.player.getPosition(),
                });
                break;

            // auto changing quality
            case 'visualQuality':
                self.gemiusService.sendHit(self.player.getPosition(), 'chngQual');
                break;

            // before complete
            case 'beforeComplete':
                break;

            // complete
            case 'complete':
                self.gemiusService.sendHit(self.player.getPosition(), 'complete');
                self.sendAppService('sentPlayerEventWithTime', {
                    eventType: 'player_end',
                    timePosition: self.player.getPosition(),
                });
                break;

            // float
            case 'float':
                self.sendAppService('setPlayerProperty', {
                    propertyName: 'playerVariant',
                    propertyValue: info.floating ? 'player_attach' : 'player_detach',
                });
                self.sendAppService('sentPlayerData', {
                    eventType: '',
                    time: self.player.getPosition(),
                    clear: true,
                });
                break;

            // fullscreen
            case 'fullscreen':
                self.gemiusService.sendHit(self.player.getPosition(), 'chngRes');
                self.sendAppService('sentPlayerSetting', {
                    eventType: 'player_settings_change_fullscreen',
                    item: 'fullscreen',
                    itemValue: info.fullscreen,
                    timePosition: self.player.getPosition(),
                });
                break;

            // error
            case 'error':
                // TODO error
                break;
            default:
        }
    };

    /**
     * @description - funkce pro odesílání události player_progress do dataLayeru každých 5s
     *
     * @param {object} timeData - objekt s časovými údaji
     * @param {number} timeData.actualTime - aktuální čas přehrávání videa (s)
     * @param {number} timeData.oldTime - čas uložený při posledním odeslání události do objectu playeru
     */
    self.playerProgress = (timeData) =>
        new Promise((resolve) => {
            const actualTime = Math.round(timeData.actualTime);
            const { oldTime } = timeData;
            const playerProgress = actualTime - oldTime;

            if (playerProgress > 4) {
                self.sendAppService('sentPlayerEventWithTime', {
                    eventType: 'player_progress',
                    timePosition: actualTime,
                });
                self.playTime = actualTime;
            }
            resolve();
        });

    /**
     * listener pro sledování  události open na [sharing] pluginu a odeslání do datalayeru
     */
    self.shareEvent = () => {
        self.sendAppService('sentPlayerEventWithTime', {
            eventType: 'player_sharing_open',
            timePosition: self.player.getPosition(),
        });
    };

    self.configCNC.gemius = cfg.gemius;
    window.cncGjvpPlayers.push(self);
    return self;
}
