const VideoAdRequest = () => {
    // typ reklamni pozice preroll_{count}; midroll_2, postroll_1
    let adType = '';
    // podporovane velikosti reklamy
    const adSizes = ['1920x1080', '1280x720', '1024x576', '768x576', '640x480', '640x360', ''];
    // url do reklamniho systemu
    const tagUrl = 'https://pubads.g.doubleclick.net/gampad/ads';

    const site = () => {
        if (!window.__cncPageDefinition.site) {
            return '';
        }

        return window.__cncPageDefinition.site.startsWith('dev_')
            ? window.__cncPageDefinition.site.substring(4)
            : window.__cncPageDefinition.site;
    };

    const L2 = () => {
        const siteToL2 = {
            abicko: 'cnc260',
            ahaonline: 'cnc260',
            auto: 'cnc260',
            autorevue: 'cnc260',
            blesk: 'cnc260',
            bleskprozeny: 'cnc260',
            dama: 'cnc260',
            e15: 'cnc260',
            eleague: 'cnr260',
            evropa2: 'cnr260',
            extra: 'cnr260',
            extralife: 'cnr260',
            extrastory: 'cnr260',
            frekvence1: 'cnr260',
            gcz: 'cnr260',
            hledejceny: 'cnc260',
            hokej: 'cnr260',
            info: 'cnr260',
            isport: 'cnc260',
            lideazeme: 'cnc260',
            lifee: 'cnr260',
            malltv: 'cnr260',
            maminka: 'cnc260',
            mimibazarcz: 'cnc260',
            mimibazarsk: 'cnc260',
            mobilmania: 'cnc260',
            mojezdravi: 'cnc260',
            nhl: 'cnr260',
            onetv: 'cnr260',
            recepty: 'cnc260',
            reflex: 'cnc260',
            sparta: 'cnr260',
            sportrevue: 'cnc260',
            toprecepty: 'cnr260',
            youradio: 'cnr260',
            zeny: 'cnc260',
            zive: 'cnc260',
        };

        return siteToL2[site()] || null;
    };

    /**
     * @name getArea
     *
     * @param {Object} [cncPageDefinition]
     * @param {string} [cncPageDefinition.forceArea]
     * @param {string} [cncPageDefinition.site]
     * @returns {string} 'zpravy' pro Blesk Zprávy, jinak 'ostatni'
     */
    const getArea = (cncPageDefinition) =>
        cncPageDefinition &&
        cncPageDefinition.forceArea === 'zpravy' &&
        cncPageDefinition.site === 'blesk'
            ? 'zpravy'
            : 'ostatni';

    const getUnit = () => {
        if (window && window.__cncPageDefinition && window.__cncPageDefinition.site) {
            const _site = site();
            const _L2 = L2();
            const area = getArea(window.__cncPageDefinition);
            const adUnit = ['/21869710533'];
            const template = 'bigplayer_play';
            const targetPosition = adType;

            if (L2) {
                adUnit.push(_L2);
                if (site) {
                    adUnit.push(_site);
                    if (targetPosition) {
                        adUnit.push(targetPosition);
                        if (template) {
                            adUnit.push(template);
                            adUnit.push(area);
                        }
                    }
                }
            }

            return adUnit.join('/');
        }

        return '';
    };

    const getQueryParams = () => {
        // query params do url
        const queryParams = {
            iu: getUnit(),
            tfcd: '0',
            npa: '0',
            sz: encodeURIComponent(adSizes.join('|')),
            gdfp_req: '1',
            output: 'vast',
            unviewed_position_start: '1',
            env: 'vp',
            impl: 's',
            plcmt: '2',
        };

        const arr = Object.entries(queryParams).map((entry) => `${entry[0]}=${entry[1]}`);

        return arr.join('&');
    };
    const getUrlTargeting = () => window.location.pathname.split('/').toString();

    const getBisko = () => {
        try {
            return localStorage.cxSegments || '';
        } catch {
            return '';
        }
    };

    const getKeywords = () => {
        if (
            window &&
            window.__cncPageDefinition &&
            window.__cncPageDefinition.keywords &&
            Array.isArray(window.__cncPageDefinition.keywords)
        ) {
            return window.__cncPageDefinition.keywords.join(',');
        }

        return '';
    };

    const getBrandSafety = () => {
        let brandSafety;
        if (
            window &&
            window.__cncPageDefinition &&
            window.__cncPageDefinition.safety &&
            Array.isArray(window.__cncPageDefinition.safety)
        ) {
            brandSafety = window.__cncPageDefinition.safety;
        } else {
            brandSafety = [];
        }

        return brandSafety.join(',');
    };

    const getCustomParams = () => {
        const customParams = {};

        customParams.L2 = L2();
        customParams.site = site();
        customParams.template = 'bigplayer_play';
        customParams.target_position = adType;
        customParams.area = getArea(window ? window.__cncPageDefinition : {});
        customParams.URL = getUrlTargeting();
        customParams.keywords = getKeywords();
        customParams.safety = getBrandSafety();
        customParams.bisko = getBisko();

        const customParamsArray = Object.entries(customParams).map(
            (entry) => `${entry[0]}=${entry[1]}`,
        );

        let result = `cust_params=${encodeURIComponent(customParamsArray.join('&'))}`;

        // vprerollu musi zustat neencodovane { a} aby codex mohl nahradit preroll_{count} za preroll_1 podle poradi volani
        result = result.replace(/%7B/g, '{');
        result = result.replace(/%7D/g, '}');

        return result;
    };

    return {
        /**
         * ziskani url pro pre roll
         * @returns {string}
         */
        getPreRollUrl() {
            adType = 'preroll_{count}';
            return `${tagUrl}?${getQueryParams()}&${getCustomParams()}`;
        },

        /**
         * ziskani url pro mid roll
         * @returns {string}
         */
        getMidRollUrl() {
            adType = 'midroll_1';
            return `${tagUrl}?${getQueryParams()}&${getCustomParams()}`;
        },

        /**
         * ziskani url pro post roll
         * @returns {string}
         */
        getPostRollUrl() {
            adType = 'postroll_1';
            return `${tagUrl}?${getQueryParams()}&${getCustomParams()}`;
        },
    };
};

export default VideoAdRequest;
